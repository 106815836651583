import { wrappedError } from '#/errors'

export default wrappedError('MicrophoneError', error => {
  if (error instanceof DOMException) {
    switch (error.name) {
      case 'AbortError':
      case 'NotReadableError':
      case 'OverconstrainedError':
      case 'InvalidStateError':
      case 'NotSupported':
      case 'TypeError': {
        return 'notUsable'
      }

      case 'NotAllowedError':
      case 'SecurityError': {
        return 'noPermission'
      }

      case 'NotFoundError': {
        return 'notFound'
      }
    }
  }

  return 'unknown'
})
