import type { Props } from '../types.js'
import type { Datum } from '^/app/store/index'

import {
  Buttons,
  ButtonLabel,
  ButtonPlain,
} from '@/common/index'

import useFocusNavigation from '#/hooks/useFocusNavigation'
import { useAssessmentContext } from '../context.js'


export default function AssessmentQuestionRadio(props: Props.One<'radio'>) {
  const {
    value,
    question: {
      id,
      name,
      optional,
      constrain: {
        options: unnormalizedOptions,
      },
    },
  } = props


  const { actions } = useAssessmentContext()
  
  const handlers = useMemo(() => {
    function select(label: HTMLLabelElement) {
      const input = label.querySelector<HTMLInputElement>('input:checked')

      if (input) {
        actions.select({
          id,
          value: uncastValue(input.value),
        })
      }
    }

    return {
      change(event: Dx.Event.Change<'label'>) {
        select(event.currentTarget)
      },
      click(event: Dx.Event.Mouse<'label'>) {
        if (event.target.tagName === 'LABEL') {
          select(event.currentTarget)
        }
      },
    }
  }, [actions, id])

  const ref = useFocusNavigation({
    keys: {
      Enter: { click: 'button, label' },
      ' ': { click: 'button, label' },
    },
  })

  const options = useMemo(() =>
    unnormalizedOptions.map(option =>
      typeof option === 'string' ?
        { label: option, value: option } :
        option
    )
  , [unnormalizedOptions])

  return (
    <>
      <Buttons ref={ref}>
        {options.map((option, index) =>
          <ButtonLabel
            key={index}
            className='min-w-16'
            onChange={handlers.change}
            onClick={handlers.click}
            tabIndex={0}
            size='lg'
            data-autofocus={value === option.value}>
            {option.label.toUpperFirst()}

            <input
              className='absolute z-0 top-0 left-0 w-0 h-0 opacity-0'
              type='radio'
              tabIndex={-1}
              name={name}
              required={!optional}
              value={castValue(option.value)}
              defaultChecked={value === option.value} />
          </ButtonLabel>
        )}
      </Buttons>


      <ButtonPlain
        type='button'
        size='sm'
        className='mt-9 text-gray-500 font-normal'
        onClick={() => actions.next()}>
        Skip this question
      </ButtonPlain>
    </>
  )
}



function castValue(value: Datum.Value.Option) {
  return String(value)
}

function uncastValue(value: string) {
  return (
    value === 'true' ? true :
    value === 'false' ? false :
    value
  )
}
