import type {
  Input,
  Movement,
  Progress,
  Question,
  Slots,
} from './types.js'

import type {
  Datum,
  Tables,
  Traits,
} from '^/app/store/index'

import type { Trait } from '^/app/store/types'

import {
  prepareQuestion,
} from './prepare.js'

import { fillSlots } from './slots.js'
import createSequence from './sequence.js'
import createHash from '#/hash'
import { getInput } from './getInput.js'
import computeProgress from './computeProgress.js'


type UpcomingQuestion = {
  question: Question.Any
  invalidate: boolean
  input: Input
  trailHash: string
  tokenHash: string
}


export type Data = {
  assessmentId: string
  datums?: Datum[]
  questions: Question.Prepared.Any[]
  sessionId: Tables.Sessions.Key
  slots: Slots
  snippet?: string
  snippetName?: string,
  skipQuestionNames?: Set<string>,
  stack?: number[]
  subjectId: Tables.Subjects.Key
  summary?: string
  traitDatums?: Datum[]
  traceId: string
}

export type Actions = {
  add: (payload: { question: Question.Definition<AnyRecord<string>>, position?: 'next' | 'tail', advance?: boolean }) => void
  clearTraits: () => void
  initialize: () => void
  move: (payload: { onLoad?: OnLoad, movement?: Movement }) => void
  next: () => void
  previous: () => void
  reset: () => void
  select: (payload: { id: number, value: Datum.Value.Any }) => void
  set: (payload: { id: number, value: Datum.Value.Any }) => void
  setTrait: <N extends Trait.Name>(payload: { name: N, value: Traits.ByName[N]['value'] }) => void
  setTraits: (traits: Datum[]) => void
}

export type Methods = {
  // determineNext: (options?: { from?: number }) => {
  //   question: Question.Any | null
  //   invalidate: boolean
  //   isLoading: boolean
  //   input: Input | null
  // }

  determineUpcoming: (options?: { from?: number }) => {
    upcoming: UpcomingQuestion[]
    isLoading: boolean
    stack: number[]
    skipped: number[]
  }

  getInput: (options: { stack: number[] }) => Input
}

type OnLoad = 'next' | 'reset' | 'initialize'


export const useAtom = Matter
  .state((data: Data) => {
    const {
      assessmentId,
      datums: datums_ = Array._,
      questions: questions_,
      sessionId,
      slots,
      snippet,
      snippetName,
      skipQuestionNames = new Set(),
      subjectId,
      summary,
      stack,
      traitDatums = null,
      traceId,
    } = data

    const questions = questions_.map(q => fillSlots(q, slots))
    const questionsById = questions.keyBy('id')
    const questionsByName = questions.keyBy('name')
    const questionIds = questions.mapBy('id')

    const datums: Datum[] = datums_
      .filter(datum => datum.value !== undefined)
      .map(datum => ({
        ...datum,
        refId: questionsByName[datum.name]?.id,
      }))

    const skipQuestionIds = new Set(skipQuestionNames.map(name => questionsByName[name]?.id).compact())

    const state = {
      datums,
      questions,
      skipQuestionIds,
      snippet,
      snippetName,
      summary,
      questionsById,
      questionIds,
      sequence: createSequence(questionIds.max()),

      // Input
      input: {
        datums: [],
        tokenHash: '',
        trailHash: '',
        questionHash: '',
        tokens: [],
        traitDatums: [],
      } as Input,

      // External
      assessmentId,
      sessionId,
      subjectId,
      slots,
      traitDatums,
      traceId,
      progress: null as Progress | null,

      // Display
      invalidate: true,
      movement: 'none' as Movement,
      onLoad: null as OnLoad | null,
      question: null as Question.Any | null,
      stack: [] as number[],
      waiting: false,

      initial: {
        datums,
        questions,
        snippet,
      },
    }

    if (stack) {
      const set = new Set(state.questionIds)
      state.stack = stack.filter(id => set.has(id))
      state.question = state.questionsById[stack.last()] ?? null
    }

    return state
  })

  .methods<Methods>({
    getInput(state, options) {
      const {
        datums: allDatums,
        questionsById,
      } = state

      const {
        stack,
      } = options

      const previousQuestionHash = questionsById[stack.last()]?.hash ?? ''
      
      const {
        datums,
        traitDatums,
        tokens,

      } = getInput({
        datums: allDatums,
        stack,
      })

      const tokenHash = createHash(JSON.stringify(tokens, null, 2))
      const trailHash = createHash(JSON.stringify({
        tokenHash,
        previousIds: stack,
        previousQuestionHash,
      }, null, 2))

      return {
        datums,
        tokenHash,
        trailHash,
        questionHash: previousQuestionHash,
        tokens,
        traitDatums,
      }
    },

    determineUpcoming(state, options = {}) {
      const {
        questions,
        stack: stack_,
        traitDatums,
        questionIds,
        skipQuestionIds,
      } = state

      const {
        from,
      } = options

      const stack = from == null ? [...stack_] : stack_.slice(0, from)
      const start = questionIds.indexOf(stack.last()) + 1
      const skipped = [] as number[]

      const upcoming: UpcomingQuestion[] = []

      for (const [index, question] of questions.slice(start).entries()) {
        const input = this.getInput({ stack })

        // Skip questions if desired
        if (skipQuestionIds.has(question.id)) {
          stack.push(question.id)
          skipped.push(question.id)

          // skipQuestionIds.delete(question.id)
          continue
        }

        // Check for questions that populate traits
        if (question.trait) {
          const trait = input.traitDatums.find(d => d.traitId === question.trait)

          if (trait) {
            // Skip this question if the trait already has a value
            if (is.present(trait.value)) {
              continue
            }

          // Traits have not yet loaded
          } else if (traitDatums === null && index === 0) {
            return {
              upcoming,
              isLoading: true,
              stack,
              skipped,
            }
          }
        }

        // Check question conditions
        if (question.condition) {
          const values = input.datums.keyBy('name')

          const satisfied = Object
            .entries(question.condition)
            .every(([key, expect]) => {
              const value = values[key]?.value

              const result = expect === '!undefined' ?
                value === undefined :
                value === expect

              return result
            })

          // Skip question if condition is not satisifed
          if (!satisfied) {
            continue
          }
        }

        const lastTrailHash = question.trailHash
        const invalidate = lastTrailHash !== input.trailHash

        // Skip over ephemeral questions whose inputs have not changed
        if (question.ephemeral && !invalidate) {
          stack.push(question.id)
          continue
        }

        upcoming.push({
          question,
          trailHash: input.trailHash,
          tokenHash: input.tokenHash,
          input,
          invalidate,
        })
      }

      return {
        upcoming,
        isLoading: false,
        stack,
        skipped,
      }
    },
  })

  .actions<Actions>({
    initialize(state) {
      if (state.stack.length === 0) {
        this.move({
          onLoad: 'initialize',
          movement: 'none',
        })
      }
    },

    // Ignores stack
    next() {
      this.move({
        onLoad: 'next',
        movement: 'next',
      })
    },

    reset() {
      this.move({
        onLoad: 'reset',
        movement: 'reset',
      })
    },

    move(state, options = {}) {
      const {
        questionsById,
      } = state

      const {
        onLoad,
        movement = 'next',
      } = options

      const {
        upcoming,
        isLoading,
        stack,
        skipped,

      } = this.determineUpcoming({
        from: movement === 'reset' ? 0 : undefined,
      })

      const [next] = upcoming

      if (isLoading) {
        if (onLoad == null) {
          throw new Error('Cannot move next without onLoad')
        }

        state.onLoad = onLoad
        state.waiting = true

      } else if (next) {
        if (movement === 'reset') {
          state.stack = []
        }

        state.stack.push(...skipped)
        next.question.trailHash = next.input.trailHash
        next.question.tokenHash = next.input.tokenHash
        state.question = next.question
        state.stack.push(next.question.id)
        state.movement = movement
        state.invalidate = next.invalidate

        state.progress = next.question.progress ?
          computeProgress(stack.map(id => questionsById[id]), upcoming.mapBy('question')) :
          null

        if (next.input) {
          state.input = next.input
        }
      }
    },

    previous(state) {
      if (state.stack.length <= 1) {
        return
      }

      state.stack.pop()

      while (state.stack.length > 1) {
        const id = state.stack.last()
        const question = state.questionsById[id]

        if (question?.ephemeral) {
          state.stack.pop()
          continue
        }

        break
      }

      state.invalidate = false
      state.question = state.questionsById[state.stack.last()]
      state.movement = 'previous'
      state.input = this.getInput({ stack: state.stack })

      if (state.question.progress) {
        const { upcoming, stack } = this.determineUpcoming()
        state.progress = computeProgress(stack.map(id => state.questionsById[id]), upcoming.mapBy('question'), -1)

      } else {
        state.progress = null
      }
    },


    set(state, payload) {
      const {
        id,
        value,
      } = payload

      const question = state.questionsById[id]

      if (!question) {
        return
      }

      const current = state.datums.find(v => v.refId === id)

      const next = {
        assessmentId: state.assessmentId,
        context: question.contextual ? question.text : undefined,
        name: question.name,
        refId: id,
        sessionId: state.sessionId,
        source: 'assessment' as const,
        subjectId: state.subjectId,
        traitId: question.trait,
        value: (current && Object.isObject(current.value) && Object.isObject(value) ?
          { ...current.value, ...value } :
          value),
      }

      if (question.name === state.snippetName) {
        const snippet =
          typeof value === 'string' ? value :
          Object.isObject(value) && typeof value.text === 'string' ? value.text :
          ''

        state.snippet = snippet.trim().toUpperFirst()
      }

      current ?
        Object.assign(current, next) :
        state.datums.push(next)
    },

    select(_state, value) {
      this.set(value)
      this.next()
    },


    add(state, { question, position = 'tail', advance = false }) {
      const prepared = prepareQuestion(question, state)
      const filled = fillSlots(prepared, state.slots)

      const id = state.stack.last()
      const at = state.questions.findIndex(q => q.id === id) + 1
      const next = state.questions[at]


      if (position !== 'next' || id == null || !next) {
        state.questions.push(filled)

      // The question has changed
      } else if (filled.hash !== next.hash) {
        state.questions = [...state.questions.slice(0, at), filled]
      }

      state.questionIds = state.questions.mapBy('id')
      state.questionsById = state.questions.keyBy('id')

      if (advance) {
        this.next()

      } else {
        const {
          upcoming,
          stack,
        } = this.determineUpcoming({ from: -1 })

        state.progress = computeProgress(stack.map(id_ => state.questionsById[id_]), upcoming.mapBy('question'))
      }
    },

    setTraits(state, traitDatums) {
      state.traitDatums = traitDatums
      state.datums = Object.values({
        ...traitDatums.keyBy('name'),
        ...state.datums.keyBy('name'),
      })

      if (state.onLoad) {
        const next = state.onLoad
        state.onLoad = null
        state.waiting = false
        this[next]()
      }
    },

    setTrait(state, payload) {
      const { name, value } = payload
      const current = state.datums.find(v => v.traitId === name)

      const next = {
        assessmentId: state.assessmentId,
        name,
        sessionId: state.sessionId,
        source: 'assessment' as const,
        subjectId: state.subjectId,
        traitId: name,
        value,
      }

      current ?
        Object.assign(current, next) :
        state.datums.push(next)
    },

    clearTraits(state) {
      state.datums = state.datums.filter(d => !d.traitId)
      state.traitDatums = []
    },
  })
