import {
  getSelection,
  selectAll,
  doesSupportSelection,
} from '#/dom/selection'

import {
  hasAncestorMatching,
} from '#/dom/traversal'

import {
  focusRelativeElement,
  getActiveElement,
} from '#/dom/focus'


type SelectionAnchor = 'start' | 'end'

type Key = {
  offset?: number
  anchor?: SelectionAnchor
  group?: string
  unless?: string
  click?: string | true
}

type Options = {
  selector: string
  keys: Record<string, Key>
}

const defaultOptions: Options = Object.deepImmutable({
  selector: 'input, textarea, button, label[tabindex]',
  keys: {
    ArrowLeft: { offset: -1, anchor: 'start' },
    ArrowRight: { offset: 1, anchor: 'end' },
    ArrowUp: { offset: -1, group: '[data-focus-group]' },
    ArrowDown: { offset: 1, group: '[data-focus-group]' },
    Enter: { offset: 1, click: 'button' },
  },
})


export default function useFocusNavigation<T extends HTMLElement>(options: Partial<Options> = defaultOptions) {
  const ref = useRef<T>(null)

  const mergedOptions = useMemoObject({
    ...defaultOptions,
    ...options,
    keys: {
      ...defaultOptions.keys,
      ...options.keys,
    },
  })

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    const parent = ref.current

    if (!parent || !isElementTarget(event)) {
      return
    }

    const {
      keys,
      selector,
    } = mergedOptions

    const {
      key,
      target,
    } = event

    const config = keys[key]

    if (!config || !target.matches(selector)) {
      return
    }

    const {
      anchor,
      unless,
      click,
      group,
      offset = 1,
    } = config

    if (click && (click === true || target.matches(click))) {
      event.preventDefault()
      event.stopPropagation()
      target.click()
      return
    }

    if (anchor && !isSelectionAnchored(target, anchor)) {
      return
    }

    if (unless && hasAncestorMatching(target, unless)) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    focusRelativeElement({
      relativeTo: target,
      parent,
      selector,
      groupSelector: group,
      offset: offset * getFocusDirection(event),
    })

    selectAll(getActiveElement())
  }, [mergedOptions])


  return useCallback((context: T | null) => {
    const current = ref.current
    current?.removeEventListener('keydown', onKeyDown)

    ref.current = context
    context?.addEventListener('keydown', onKeyDown)
  }, [onKeyDown])
}


function isElementTarget(event: KeyboardEvent): event is Merge<KeyboardEvent, { target: HTMLElement }> {
  return Boolean(event.target && 'tagName' in event.target)
}


function isSelectionAnchored(target: HTMLElement, anchor: SelectionAnchor) {
  if (!doesSupportSelection(target)) {
    return true
  }

  const selection = getSelection(target)

  if (!selection) {
    return true
  }

  const position = anchor === 'start' ? 0 : target.value.length
  return position === selection?.[anchor]
}

function getFocusDirection(event: KeyboardEvent) {
  return event.key === 'Tab' && event.shiftKey ? -1 : 1
}