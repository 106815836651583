import type {
  Props,
} from '../types.js'

import {
  Buttons,
  ButtonPrimary,
  Icon,
  TextInput,
} from '@/common/index'


export default function AssessmentQuestionText(props: Props.One<'text'>) {
  const {
    autoFocus,
    value,
    question: {
      name,
      text,
      optional,
      attributes: {
        placeholder,
      },
    },
  } = props

  return (
    <>
      <TextInput
        type='text'
        name={name}
        autoFocus={autoFocus}
        defaultValue={String(value ?? '')}
        placeholder={placeholder === true ? text : placeholder}
        required={!optional} />

      <Buttons>
        <ButtonPrimary type='submit'>
          Continue
          <Icon name='arrow-right' className='ml-2' />
        </ButtonPrimary>
      </Buttons>
    </>
  )
}
