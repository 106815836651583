import EventEmitter from '#/EventEmitter'

import type {
  Action,
  ActionName,
  Actions,
  Context,
  ErrorActionName,
  Events,
  Event,
  EventName,
  Properties,
  States,
  Task,
  Transitions,
  Writable,
} from './types.js'


const any = '*'
  

export default class StateMachine<const A extends Actions, const X extends Context, const E extends ErrorActionName<A>, const T extends Transitions<A, X, E>> extends EventEmitter<Events<A>> {
  #state: States<A>
  #actions: A
  #transitions: T
  #tasks: Task<A>[] = []
  #initialContext: Writable<X>
  #initialState: States<A>
  #errorAction: E
  #abortCount = 0

  context: Writable<X>

  constructor(properties: Properties<A, X, E, T>) {
    super()
    this.#state = this.#initialState = properties.initialState
    this.#actions = properties.actions
    this.#transitions = properties.transitions
    this.#initialContext = properties.context
    this.#errorAction = properties.errorAction
    this.context = { ...this.#initialContext }
  }

  get state() {
    return this.#state
  }

  get pendingStates() {
    return this.#tasks.map(t => t.state)
  }

  get<const P extends string>(path: P, require: true): NonNullable<Get<Writable<X>, P>>
  get<const P extends string>(path: P, require?: false): Get<Writable<X>, P>
  get<const P extends string>(path: P, require = false) {
    const value = Object.get(this.context, path)

    if (require && value == null) {
      throw new Error(`No value in context at path '${path}'`)
    }

    return value
  }

  set<const P extends string, V extends Get<Writable<X>, P>>(path: P, value: V) {
    Object.set(this.context, path, value)
    return value
  }

  can(action: ActionName<A> & string) {
    return this.validActions().some(a => a === action)
  }

  is(state: States<A> & string) {
    return this.#latestState === state
  }

  willBe(state: States<A> & string) {
    return this.#latestState === state
  }

  hasValidAction() {
    return this.validActions().length > 0
  }

  validStates(): Array<States<A> & string> {
    return this.#validActions().map(t => t.to)
  }

  validActions(): Array<ActionName<A> & string> {
    return this.#validActions().map(t => t.name)
  }

  async try<N extends ActionName<A>>(name: N) {
    // console.log(`!try:${name}`)
    if (this.#actions[name].to === this.#state) {
      return true
    }

    if (this.can(name)) {
      return this.to(name).catch(() => false)
    }
    
    return false
  }

  async to<N extends ActionName<A>>(name: N) {
    // console.log(`!to:${name}`)
    const tail = this.#tail
    const current = tail?.state ?? this.#state

    const action = this.#actions[name]

    if (this.#state === action.to) {
      // console.warn(`Already in state '${action.to}'`)
      return
    }

    const next = this.#validActions().find(t =>
      (t.from.has(any) || t.from.has(current)) &&
      t.name === name
    )

    if (!next) {
      // console.log(`!to:${name}`, 'cannot transition', current, name)
      throw new Error(`Cannot transition from '${current}' using action '${name}'`)
    }

    const work = Promise.future<boolean>()
    const { to: state } = next

    const event: Event<A> = {
      action: name,
      to: state,
      from: current,
    }

    const emit = (eventName: EventName<A>) => this.emit(eventName, ...[event] as any)

    emit('enqueue')
    emit(`enqueue:${name}`)

    this.#tasks.push({ state, work })

    if (tail) {
      const success = await tail.work

      if (!success) {
        work.resolve(false)
        return false
      }
    }


    emit('before')
    emit(`before:${name}`)

    emit('leave')
    emit(`leave:${state}`)

    emit('action')
    emit(name)


    const fn = this.#transitions[name]

    try {
      if (fn) {
        await fn.call(this)
      }

      emit('enter')
      emit(`enter:${state}`)
      emit(state)

      this.#state = state
      this.#tasks.shift()

      emit('after')
      emit(`after:${name}`)
      work.resolve(true)
      return await work // not necessarily `true` – could have been aborted previously

    } catch (error) {
      this.#tasks.shift()
      this.emit('error', ...[error] as any)
      work.resolve(false)
      await this.abort()
      throw error
    }
  }

  async abort() {
    if (this.#abortCount++ > 5) {
      return
    }


    this.#tasks.forEach(task => {
      // console.warn('aborting', task.state)
      task.work.resolve(false)
    })

    this.#tasks = []

    if (this.#errorAction) {
      await this.try(this.#errorAction as any)
    }
  }

  async reset() {
    // await this.abort()
    this.context = { ...this.#initialContext }
    this.#state = this.#initialState
  }

  get #tail() {
    return this.#tasks[this.#tasks.length - 1]
  }

  get #latestState() {
    return this.#tail?.state ?? this.#state
  }

  #validActions() {
    const latest = this.#latestState

    return Object
      .values(this.#actions)
      .filter(a =>
        a.from.has(any) ||
        a.from.has(latest)
      ) as Action<States<A>, ActionName<A>>[]
  }
}
