type prepareInputOptions = Dx.Api.Ai.AnyViewParams & {
  criteria?: Dx.Ai.Model.Criteria
  tokens: Dx.Ai.Token[]
  traceId: string
}

type ImageToken = Dx.Ai.Token<{
  image?: string,
  imageDescription?: string
}>

type PreparedAiOutput = {
  isProcessingImages: boolean
  isValid: boolean
  input: Dx.Api.Ai.In
}


export default function prepareInput(options: prepareInputOptions): PreparedAiOutput {
  const {
    criteria,
    tokens: tokens_,
    view,
    phase,
    traceId,
  } = options

  const imageTokens = findImageTokens(tokens_)

  const tokens = produce(tokens_, draft => {
    findImageTokens(draft).forEach(token => {
      delete token.value.image
    })
  })

  if (imageTokens.some(token => !token.value.imageDescription)) {
    return {
      isProcessingImages: true,
      isValid: false,
      input: {
        criteria,
        phase,
        view,
        tokens: [],
        traceId,
      } as Dx.Api.Ai.In,
    }
  }

  return {
    isProcessingImages: false,
    isValid: tokens.length > 0,
    input: {
      criteria,
      phase,
      view,
      tokens,
      traceId,
    } as Dx.Api.Ai.In,
  }
}

function findImageTokens(tokens: Dx.Ai.Token[]): ImageToken[] {
  return tokens.filter(t => (t.value as any).image) as any
}
