import type { Props } from '../types.js'

import {
  ButtonPrimary,
  Icon,
} from '@/common/index'

import Textarea from '@/Textarea/index'
import { useAssessmentContext } from '../context.js'
import Traits from '../Traits.js'


const Agreement = styled.div(`
  text-sm
  text-gray-400
`)

const AgreementLink = styled.a(`
  underline
  hover:text-rose-500
  cursor-pointer
`)

export default function AssessmentQuestionImageTextarea(props: Props.One<'imageTextarea'>) {
  const {
    autoFocus,
    value,
    submit,
    question: {
      id,
      text,
      optional,
      attributes: {
        placeholder,
        rows,
      },
    },
  } = props


  const { actions } = useAssessmentContext()

  const onEnter = useCallback((event: Dx.Event.Keyboard<'div'>) => {
    event.preventDefault()
    event.stopPropagation()
    submit()
  }, [submit])


  const onImageDescription = useCallback((imageDescription: string | null) => {
    actions.set({ id, value: { imageDescription } })
  }, [id, actions])


  return (
    <>
      <Textarea
        name='text'
        autoFocus={autoFocus}
        defaultValue={value?.text ?? ''}
        placeholder={placeholder === true ? text : placeholder}
        required={optional ? false : 'unlessImage'}
        onEnter={onEnter}
        onImageDescription={onImageDescription}
        image={{
          name: 'image',
          defaultUrl: value?.image ?? '',
          describe: true,
        }}
        rows={rows}>
        <ButtonPrimary type='submit'>
          Discover
          <Icon
            name='arrow-right'
            className='ml-2' />
        </ButtonPrimary>
      </Textarea>

      <div className='flex items-center justify-between'>
        <Traits />

        <Agreement>By starting a symptom check, you agree to the <AgreementLink href='/privacy-policy'>Privacy Policy</AgreementLink> and <AgreementLink href='/terms-of-service'>Terms of Service</AgreementLink>.</Agreement>
      </div>
    </>
  )
}
