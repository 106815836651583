import type {
  Props,
} from '../types.js'

import {
  Buttons,
  ButtonPrimary,
  Icon,
  TextInput,
} from '@/common/index'

export default function AssessmentQuestionEmail(props: Props.One<'email'>) {
  const {
    autoFocus,
    question: {
      name,
      text,
      optional,
      attributes: {
        placeholder,
      },
    },
    value,
  } = props

  return (
    <>
      <TextInput
        type='email'
        autoFocus={autoFocus}
        name={name}
        defaultValue={String(value ?? '')}
        placeholder={placeholder === true ? text : placeholder}
        required={!optional} />
        
      <Buttons>
        <div className='flex-auto' />
        <ButtonPrimary type='submit'>
          Continue
          <Icon name='arrow-right' className='ml-2' />
        </ButtonPrimary>
      </Buttons>
    </>
  )
}
