import type {
  Props,
} from '../types.js'

import {
  Buttons,
  ButtonPrimary,
  ButtonChoice,
  ButtonCheckbox,
  Spacer,
  Icon,
  ButtonLabel,
  ButtonPlain,
} from '@/common/index'

import useFocusNavigation from '#/hooks/useFocusNavigation'
import { useAssessmentContext } from '../context.js'
import { debounce } from 'lodash-es'


const Checkmark = (
  <svg className='w-4 h-3.5 hidden group-has-[:checked]:block text-blue-500' viewBox='0 0 17.837 17.837'>
    <path className='fill-current' d='M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z' />
  </svg>
)

export default function AssessmentQuestionCheckbox(props: Props.One<'checkbox'>) {
  const {
    value,
    question: {
      id,
      name,
      optional,
      constrain: {
        options: unnormalizedOptions,
      },
    },
  } = props


  const { actions } = useAssessmentContext()
  const container = useRef<'div'>()

  const onContinue = useCallback(() => {
    if (container.current) {
      const inputs = [...container.current.querySelectorAll<HTMLInputElement>('input:checked')]

      if (inputs.length) {
        const hasNone = inputs.some(i => i.value === '')

        actions.select({
          id,
          value: hasNone ?
            [] :
            inputs.map(input => input.value),
        })
      }
    }
  }, [actions, id])

  const handlers = useMemo(() => {
    const select = debounce((label: HTMLLabelElement) => {
      const current = label.querySelector<HTMLInputElement>('input')

      if (!current || !container.current) {
        return
      }

      const inputs = container.current.querySelectorAll<HTMLInputElement>('input:checked')

      if (current.value === '' && current.checked) {
        inputs.forEach(input => {
          if (input !== current) {
            input.checked = false
          }
        })

      } else if (inputs.length === 0) {
        const none = container.current.querySelector<HTMLInputElement>('input[data-none]')

        if (none) {
          none.checked = true
        }

      } else {
        const none = container.current.querySelector<HTMLInputElement>('input:checked[data-none]')

        if (none) {
          none.checked = false
        }
      }

      if (current.value === '') {
        onContinue()
      }
    }, 0, { leading: false, trailing: true })

    return {
      change(event: Dx.Event.Change<'label'>) {
        select(event.currentTarget)
      },
      click(event: Dx.Event.Mouse<'label'>) {
        const { target } = event

        if (target.tagName === 'LABEL') {
          select(target)
        }
      },
    }
  }, [onContinue])

  const ref = useFocusNavigation({
    keys: {
      Enter: { click: 'button, label' },
      ' ': { click: 'button, label' },
    },
  })

  const options = useMemo(() =>
    unnormalizedOptions.map(option =>
      typeof option === 'string' ?
        { label: option, value: option } :
        option
    )
  , [unnormalizedOptions])

  return (
    <div ref={ref} className='group/wrapper'>
      <Buttons ref={container}>
        {options.map((option, index) =>
          <ButtonChoice
            key={index}
            tabIndex={0}
            onChange={handlers.change}
            onClick={handlers.click}
            data-autofocus={Boolean(value?.includes(String(option.value)))}
            size='lg'>

            <ButtonCheckbox>
              {Checkmark}
            </ButtonCheckbox>

            {option.label}

            <input
              className='absolute opacity-0'
              type='checkbox'
              tabIndex={-1}
              name={name}
              required={!optional}
              value={String(option.value)}
              defaultChecked={Boolean(value?.includes(String(option.value)))} />
          </ButtonChoice>
        )}

        <ButtonLabel
          onChange={handlers.change}
          onClick={handlers.click}
          tabIndex={0}
          size='lg'>

          None of these

          <input
            className='absolute opacity-0'
            type='checkbox'
            data-none
            tabIndex={-1}
            name={name}
            required={!optional}
            value=''
            defaultChecked={Boolean(value && value.length === 0)} />
        </ButtonLabel>
      </Buttons>

      <Buttons className='mt-6'>
        <ButtonPlain
          type='button'
          size='sm'
          className='text-gray-500 font-normal'
          onClick={() => actions.next()}>
          Skip this question
        </ButtonPlain>

        <Spacer />

        <ButtonPrimary
         className='hidden group-has-[:checked]/wrapper:flex'
          onClick={onContinue}
          type='button'>
          Continue
          <Icon name='arrow-right' className='ml-2' />
        </ButtonPrimary>
      </Buttons>
    </div>
  )
}
