import type { Events, Event } from './types.js'
import EventEmitter from '#/EventEmitter'
import { chunkToTokens } from '#/ai/tokens/tokenChunks'
import useStream from '#/hooks/useStream'
import { StreamContext } from './hooks.js'
import { type Tables, store } from '^/app/store/index'
import Broken from '@/Broken'

type StreamProps = {
  assessmentId: Tables.Assessments.Key,
  children: React.ReactNode
  hash: string
  input: any
  isEnabled: boolean
  onDone?: () => void
  onStart?: () => void
  sessionId: Tables.Sessions.Key
  state?: Tables.Threads.Entry
  subjectId: Tables.Subjects.Key
  threadId: Tables.Threads.Key
  url: string
}


const toEvent = (token: Dx.Ai.Token, value: Dx.Ai.Token.Value = token.value) => ({ token, value })
const startEvent: Event = toEvent({ key: 'streaming', value: true, role: 'metadata', id: 'streaming' })
const doneEvent: Event = toEvent({ key: 'streaming', value: false, role: 'metadata', id: 'streaming' })


export default function Stream(props: StreamProps) {
  const {
    assessmentId,
    children,
    hash,
    input,
    isEnabled,
    sessionId,
    subjectId,
    threadId,
    url,
    state,
  } = props

  const onDone_ = useEvent(props.onDone)
  const onStart_ = useEvent(props.onStart)
  const [hasError, setHasError] = useState(false)

  const box = useBox(() => ({
    emitter: new EventEmitter<Events>(),
    values: {} as AnyRecord<string>,
    metadata: {} as AnyRecord<string>,
    input,
    valueAt: (path: string) => path.startsWith('meta:') ?
      Object.get(box.metadata, path.replace(/^meta:/, '')) :
      Object.get(box.values, path),
    on(eventName: string, fn: (event: Event) => void) {
      return this.emitter.on(eventName, fn) as () => void
    },
    emit(eventName: string, event: Event) {
      this.emitter.emit(eventName, event)
    },
  }))

  useEffect(() => {
    box.input = input
  }, [box, input])

  if (Object.isPresent(state) && Object.isEmpty(box.values)) {
    box.values = state.values ?? {}
    box.metadata.signature = state.signature
  }

  const onStart = useCallback(() => {
    box.emit('meta:streaming', startEvent)
  }, [box])

  const onDone = useCallback(() => {
    box.emit('meta:streaming', doneEvent)
  }, [box])

  const onError = useCallback((error: unknown) => {
    dx.capture(error)
    onDone()
    setHasError(true)
  }, [onDone])

  const onData = useCallback((chunk: string) => {
    for (const token of chunkToTokens(chunk)) {
      const event = { token, value: token.value }

      const { key, value } = token
      const segments = key.split('.').slice(0, -1)
      let prefix = ''

      if (token.role === 'metadata') {
        box.metadata = produce(box.metadata, current => void Object.set(current, key, value))
        prefix = 'meta:'

      } else {
        box.emit('*', event)
        box.values = produce(box.values, current => void Object.set(current, key, value))
      }

      segments.forEach((_, index) => {
        const previous = segments
          .slice(0, index + 1)
          .join('.')
        
        box.emit(`${prefix}${previous}`, {
          token,
          value: Object.get(box.values, previous),
        })
      })

      box.emit(`${prefix}${key}`, event)

      if (token.key === 'error') {
        onDone()
        setHasError(true)
        return
      }
    }
  }, [box, onDone])


  useEffect(() => box.on('meta:streaming', ({ value }) => !value && onDone_()), [box, onDone_])
  useEffect(() => box.on('meta:streaming', ({ value }) => value && onStart_()), [box, onStart_])

  useEffect(() =>
    box.on('meta:signature', () => {
      const {
        signature,
      } = box.metadata

      if (!signature) {
        return
      }

      store.threads.put({
        id: threadId,
        sessionId,
        subjectId,
        assessmentId,
        hash,
        values: box.values,
        signature,
      })
      .catch(dx.capture)
    })
  , [
    box,
    hash,
    assessmentId,
    sessionId,
    subjectId,
    threadId,
  ])

  useStream({
    url,
    body: input,
    isEnabled: isEnabled && !state && !hasError,
    method: 'post',
    onData,
    onDone,
    onError,
    onStart,
  })

  return (
    <StreamContext.Provider value={box}>
      {hasError ?
        <Broken /> :
        
        <div
          role='log'
          aria-live='polite'>
          {children}
        </div>}
    </StreamContext.Provider>
  )
}
