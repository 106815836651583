export default function useSanitizedValue(value = '') {
  return useMemo(() => {
    if (import.meta.env.SSR) {
      return ''
    }

    const node = document.createElement('div')
    node.innerText = value
    return node.innerText
  }, [value])
}