import { isActiveElement } from './focus.js'

const difficultInputTypes = ['email', 'number']


type Selectable = HTMLInputElement | HTMLTextAreaElement
type Offsets = { start: number, end: number }

type Input = HTMLElement | null | undefined


export const isSelectionSupported = doesDocumentSupportSelection()

export function focusInput(input: Input) {
  if (input && !isActiveElement(input)) {
    input.focus()
  }
}

export function activateInput(input: Input) {
  focusInput(input)
  moveCursorToEnd(input)
}

export function selectAll(input: Input) {
  setSelection(input, {
    end: getValue(input)?.length ?? 0,
    start: 0,
  })
}

export function setCursor(input: Input, position: number) {
  setSelection(input, {
    end: position,
    start: position,
  })
}

export function moveCursorToEnd(input: Input) {
  setCursor(input, getValue(input)?.length ?? 0)
}

export function getSelection(input: Input) {
  if (!doesSupportSelection(input)) {
    return
  }

  const {
    selectionStart,
    selectionEnd,
  } = input

  if (selectionStart == null || selectionEnd == null) {
    return
  }

  return {
    start: selectionStart,
    end: selectionEnd,
  }
}

export function setSelection(input: Input, offsets: Offsets) {
  if (!doesSupportSelection(input)) {
    return
  }

  let {
    end,
    start,
  } = offsets

  end = Math.clamp(end == null ? start : end, start, Infinity)
  start = Math.clamp(start, 0, Infinity)

  coerceInputType(input, () => {
    try {
      input.setSelectionRange(start, end)
    } catch (e) {}
  })
}

export function setValueWithSelection(input: Input, value: string) {
  if (!doesSupportSelection(input)) {
    return
  }

  if (isActiveElement(input)) {
    const selection = computeNewSelection(input, value)
    input.value = value

    if (selection) {
      setSelection(input, selection)
    }

  } else {
    input.value = value
  }
}


export function doesSupportSelection(element: Input): element is Selectable {
  if (!isSelectionSupported || !element || !('selectionStart' in element)) {
    return false
  }

  try {
    element.selectionStart
    return true
    
  } catch (e) {
    return false
  }
}

// TODO: This isn't very accurate.
function computeNewSelection(input: Input, newValue = '') {
  if (!doesSupportSelection(input)) {
    return
  }

  const selection = getSelection(input)

  if (!selection) {
    return
  }

  const value = input.value || ''
  const isLeftmostSame = value.slice(0, selection.start) === newValue.slice(0, selection.start)

  const start = isLeftmostSame ?
    selection.start :
    selection.start + (newValue.length - value.length)

  const end = start + (selection.end - selection.start)

  return {
    start: Math.clamp(start, 0, newValue.length),
    end: Math.clamp(end, 0, newValue.length),
  }
}

export function isSelectionFocusedAtStart(selection: Selection) {
  const {
    anchorNode,
    focusNode,
    anchorOffset,
    focusOffset,
  } = selection

  const position = anchorNode && focusNode ?
    anchorNode.compareDocumentPosition(focusNode) :
    null

  return position ?
    position === Node.DOCUMENT_POSITION_PRECEDING :
    anchorOffset > focusOffset
}


function coerceInputType(input: Selectable, callback: (el: Selectable) => void) {
  if (input.tagName === 'TEXTAREA' || input.tagName === 'SELECT') {
    return callback(input)
  }

  const type = input.type
  const isDifficult = difficultInputTypes.includes(type)

  if (isDifficult) {
    input.setAttribute('type', 'text')
  }

  callback(input)

  if (isDifficult) {
    input.setAttribute('type', type)
  }
}


type ValueElementsMap = { [K in keyof HTMLElementTagNameMap as HTMLElementTagNameMap[K] extends { value: unknown } ? K : never]: HTMLElementTagNameMap[K] }
type ValueElements = ValueElementsMap[keyof ValueElementsMap]

function getValue(input?: Input) {
  if (hasValue(input)) {
    return String(input.value)
  }
}


function hasValue(input: any): input is ValueElements {
  return input && 'value' in input
}

function doesDocumentSupportSelection() {
  if (typeof document === 'undefined') {
    return false
  }

  const input = document.createElement('input')
  input.value = 'test'

  if (!('selectionStart' in input)) {
    return false
  }

  return true
}
