import type { ResizeOptions } from 'image-blob-reduce'
import useImageDescription from './useImageDescription.js'


type ImageError =
  { type: 'invalidType', accept: Type[] } |
  { type: 'minSize', size: number } |
  { type: 'maxSize', size: number } |
  { type: 'unknown', error: unknown }

type Actions = {
  pending: (url: string) => void
  done: (url: string) => void
  reset: () => void
  error: (error: ImageError) => void
  describe: (options: { url: Nullable<string>, description: Nullable<string> }) => void
}

const useMatter = Matter
  .state((initial: { url: Nullable<string>, description: Nullable<string> }) => ({
    url: initial.url ?? null,
    previewUrl: initial.url ?? null,
    description: initial.description ?? null,
    error: null as ImageError | null,
  }))
  .actions<Actions>({
    pending(state, url) {
      state.url = state.error = null
      state.previewUrl = url
    },

    done(state, url) {
      state.url = state.previewUrl = url
      state.error = null
    },

    reset(state) {
      state.url = state.previewUrl = state.error = null
    },

    error(state, error) {
      state.error = error
      state.url = state.previewUrl = null
    },

    describe(state, { url, description }) {
      state.description = url && state.url && url === state.url ?
        description ?? null :
        null
    },
  })


export type UseImageOptions = ResizeOptions & {
  accept?: Type[]
  defaultUrl?: Nullable<string>
  defaultDescription?: Nullable<string>
  describe?: boolean
  maxDimensions?: number
  maxSize?: number
  minSize?: number
  onDescription?: (description: string) => void
}

export type Type = typeof imageTypes[number]

export const imageTypes = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/webp',
]

const defaultOptions = {
  maxDimensions: 1568,
  maxSize: 20 * 1_024 * 1_024,
  minSize: 1_000,
  accept: imageTypes,
}

export default function useImage(props: UseImageOptions = {}) {
  const {
    defaultUrl = null,
    defaultDescription = null,
    describe: shouldDescribe = false,
    onDescription: onDescription_,
    ...rest
  } = props

  const options = useMemoObject(Object.defaults(rest, defaultOptions))
  const describeImage = useImageDescription()
  const onDescription = useEvent(onDescription_)
  const [state, actions] = useMatter({ url: defaultUrl || null, description: defaultDescription || null })
  const { url } = state
  const box = useBox({ url })

  useEffect(() => {
    async function describe() {
      const description = await describeImage(url)
      onDescription(description)
      actions.describe({ url, description })
    }

    const didUrlChange = box.url !== url
    box.url = url

    if (!shouldDescribe || !didUrlChange) {
      return
    }

    if (url) {
      describe().catch((error: unknown) => {
        dx.capture(error)
        actions.describe({ url, description: null })
      })

    } else {
      actions.describe({ url, description: null })
    }
  }, [
    url,
    actions,
    describeImage,
    shouldDescribe,
    onDescription,
    box,
  ])

  const api = useMemo(() => {
    const {
      accept,
      minSize,
      maxSize,
      maxDimensions,
      ...resizeOptions
    } = options

    async function set(image: Nullable<File>) {
      actions.reset()

      if (!image) {
        return actions.reset()
      }

      if (!accept.includes(image.type)) {
        return actions.error({ type: 'invalidType', accept })
      }

      if (minSize != null && image.size < minSize) {
        return actions.error({ type: 'minSize', size: minSize })
      }

      if (maxSize != null && image.size > maxSize) {
        return actions.error({ type: 'maxSize', size: maxSize })
      }

      actions.pending(URL.createObjectURL(image))

      try {
        const resized = await resizeImage(image, {
          ...resizeOptions,
          max: maxDimensions,
        })

        actions.done(resized)

      } catch (error) {
        dx.capture(error)
        actions.error({ type: 'unknown', error })
      }
    }

    function reset() {
      actions.reset()
    }

    return {
      set,
      reset,
    }
  }, [
    actions,
    options,
  ])

  

  return useMemo(() => [state, api, options] as const, [state, api, options])
}



async function resizeImage(file: File, options: ResizeOptions) {
  const { default: resizer } = await import('image-blob-reduce')
  const canvas = await resizer().toCanvas(file, options)
  return canvas.toDataURL(file.type)
}