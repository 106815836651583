import { wrappedError } from '#/errors'
import MicrophoneError from './microphone/error.js'
import VoiceSocketError from './socket/error.js'

export default wrappedError('VoiceError', (error: unknown) => {
  if (error === 'socket' || error instanceof VoiceSocketError) {
    return 'socket'
  }

  if (error === 'microphone' || error instanceof MicrophoneError) {
    return 'microphone'
  }

  return 'unknown'
})