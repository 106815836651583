import { useStore, type StoreApi } from 'zustand'
import { createStore } from 'zustand/vanilla'

type BaseState<T> = {
  value: T
  set: (value: T) => void
  update: (fn: (value: T) => T) => void
}

type NumberState<T> = BaseState<T> & {
  decrement: () => void
  increment: () => void
}

type Signal<T> = StoreApi<
  T extends number ?
    NumberState<T> :
    BaseState<T>
>

export default function signal<T>(initialValue: T): [() => T, Signal<T>] {
  const store = createStore<any>()(set => {
    const base = {
      value: initialValue,
      set: (value: any) => set({ value }),
    }

    if (typeof initialValue === 'number') {
      Object.assign(base, {
        increment: () => set((s: BaseState<number>) => s.value + 1),
        decrement: () => set((s: BaseState<number>) => s.value + 1),
      })
    }

    return base
  })

  return [
    () => useStore(store, s => s.value),
    store,
  ]
}