const documentNodeType = 9


type Input =
  Window |
  Document |
  Element |
  HTMLElement |
  null |
  undefined

export function hasNodeName(element: Input, name: string) {
  return isElement(element) && element.nodeName && element.nodeName.toLowerCase() === name.toLowerCase()
}

export function getWindow(element: Input) {
  if (isWindow(element)) {
    return element
  }

  if (isDocument(element)) {
    return element.defaultView ?? window
  }

  if (element) {
    return element.ownerDocument.defaultView ?? window
  }

  return window
}

export function isWindow(element: Input): element is Window {
  return element != null && 'window' in element && element === element.window
}

export function isDocument(element: Input): element is Document {
  return element != null && 'nodeType' in element && element.nodeType === documentNodeType
}

export function isElement(element: unknown): element is HTMLElement {
  return element instanceof HTMLElement
}

export function isVisible(element: Input) {
  return isElement(element) && Boolean(element.offsetWidth || element.offsetHeight || element.getClientRects().length)
}

export function escapeHtml(html: string) {
  return html
    .replace(/&/g, '&amp;')
    .replace(/\s/g, '&nbsp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
}