import { reducedMotion } from '#/dom/media'
import type { Movement } from './types.js'

type AssessmentHeaderProps = {
  children: React.ReactNode
  isVisible: boolean
  movement: Movement
}


const Wrapper = styled.div(`
  motion-safe:transition-all
  motion-safe:duration-400
  overflow-hidden
`)

const Content = styled.div(`
  h-max
  pb-6
`)

export default function AssessmentHeader(props: AssessmentHeaderProps) {
  const {
    children,
    isVisible,
    movement,
  } = props

  const box = useBox(_ => ({
    wrapper: _<'div'>(),
    content: _<'div'>(),
    wasVisible: isVisible,
  }))

  const [initialStyle] = useState(() => ({ maxHeight: isVisible ? '' : '0px' }))

  useEffect(() => {
    if (box.wrapper && box.content && isVisible !== box.wasVisible) {
      box.wasVisible = isVisible

      if (reducedMotion.matches) {
        box.wrapper.style.maxHeight = isVisible ? `${box.content.offsetHeight}px` : '0px'
        return
      }

      if (movement === 'none') {
        box.wrapper.style.transitionProperty = 'none'
      }

      if (isVisible) {
        box.wrapper.style.maxHeight = '0px'
        box.wrapper.offsetHeight
        box.wrapper.style.maxHeight = `${box.content.offsetHeight}px`

      } else {
        box.wrapper.style.maxHeight = `${box.content.offsetHeight}px`
        box.wrapper.offsetHeight
        box.wrapper.style.maxHeight = '0px'
      }
    }
  }, [isVisible, movement, box])

  return (
    <Wrapper
      ref={box.ref('wrapper')}
      style={initialStyle}
      suppressHydrationWarning>
      <Content ref={box.ref('content')}>{children}</Content>
    </Wrapper>
  )
}
