import type {
  Progress,
  Question,
} from './types.js'

export default function computeProgress(past: Question.Any[], future: Question.Any[], offset = 0): Progress | null {
  let total = 0
  let current = 0

  for (let i = past.length - 1; i >= 0; i--) {
    if (past[i].progress) {
      total++

    } else {
      break
    }
  }

  current = total + offset

  for (const question of future) {
    if (question.progress) {
      total++

    } else {
      break
    }
  }

  return {
    total,
    current,
  }
}
