import type { Datum } from '^/app/store/types'

type GetInputOptions = {
  stack: number[]
  datums: Datum[]
}

export function getInput(options: GetInputOptions) {
  const {
    stack,
    datums,
  } = options

  const previousIds = new Set(stack)

  // Datums valid for input into this question
  const inputDatums = datums.filter(datum =>
    datum.traitId ||
    typeof datum.refId !== 'number' ||
    previousIds.has(datum.refId)
  )

  // Traits valid for input into this question
  const traitDatums = inputDatums.filter(d =>
    d.traitId &&
    d.external
  )

  const tokens = inputDatums
    .filter(datum => datum.value != null)
    .map(datum => ({
      id: [datum.subjectId, datum.sessionId, datum.refId, datum.name].compact().join('-'),
      key: datum.name,
      value: datum.value,
      context: datum.context,
      role: 'user' as const,
    }))

  return {
    datums: inputDatums,
    tokens,
    traitDatums,
  }
}
