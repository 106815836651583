import { debounce } from 'lodash-es'
import { hasAncestor } from './dom/traversal.js'
import { isElement } from './dom/utils.js'


type WatchTransitionOptions = {
  propertyName: string
  selector?: string
  timeout?: number
  fn: () => void
}

export type Transition = {
  detatch: () => void
  expected: () => void
}

type TransitionState = 'idle' | 'pending' | 'running'

export function watchTransition(element: HTMLElement, options: WatchTransitionOptions): Transition {
  const {
    propertyName,
    selector,
    fn,
    timeout = 1_00,
  } = options

  document.body.addEventListener('transitionrun', run)
  document.body.addEventListener('transitionend', stop)
  element.addEventListener('transitionend', stop)
  element.addEventListener('transitioncancel', stop)

  let timer: null | number = null
  let state: TransitionState = 'idle'
  let threshold = 0


  const matches = matcher(element, propertyName, selector)

  const execute = debounce(() => {
    update('idle')
    fn()
  }, 0)


  function update(nextState: TransitionState) {
    state = nextState

    if (timer != null) {
      clearTimeout(timer)
      timer = null
    }
  }

  function expected() {
    update('pending')
    timer = setTimeout(execute, timeout) as unknown as number
  }

  function run(event: TransitionEvent) {
    if (state === 'pending' && matches(event)) {
      update('running')
      threshold = event.timeStamp
    }
  }

  function stop(event: TransitionEvent) {
    if (state === 'running' && matches(event) && event.timeStamp >= threshold) {
      execute()
    }
  }


  function detatch() {
    update('idle')
    document.body.removeEventListener('transitionrun', run)
    document.body.removeEventListener('transitionend', stop)
    element.removeEventListener('transitionend', stop)
    element.removeEventListener('transitioncancel', stop)
  }

  return {
    detatch,
    expected,
  }
}

function matcher(element: HTMLElement, property: string, selector?: string) {
  return selector ?
    ({ propertyName, target }: TransitionEvent) => propertyName === property && isElement(target) && target.matches(selector) && hasAncestor(target, element) :
    ({ propertyName, target }: TransitionEvent) => propertyName === property && element === target
}