import { Icon } from '@/common/Icon'
import ProgressBar from './ProgressBar.js'
import { ButtonDiv } from '@/common/buttons'
import type { Actions } from './atom.js'
import type { Progress } from './types.js'

const Slider = styled.div(`
  h-0
  absolute
  left-0
  right-0
  z-[1]
  flex
  -top-5
`)

const Container = styled.div(({ isVisible }: { isVisible: boolean }) => [`
  w-full
  print:hidden
  pointer-events-none
  opacity-0
  motion-safe:transition-all
  motion-safe:duration-200
  opacity-0
  -translate-y-8
  relative
`, {
  [`
    opacity-100
    translate-y-0
    pointer-events-auto
  `]: isVisible,
}])


export const Back = styled.button(`
  -mr-4
  pr-4
  pt-5
  hover:*:text-blue-500
  hover:*:ring-blue-500
  cursor-pointer
  absolute
  right-0
  top-2
`)

export const BackInner = styled(ButtonDiv, `
  z-10
  ring-gray-50
  text-gray-400
  bg-white
`)


type ControlsProps = {
  actions: Actions
  progress: Progress | null
  isVisible: boolean
}

export default function Controls(props: ControlsProps) {
  const {
    actions,
    progress,
    isVisible,
  } = props

  return (
    <Slider>
      <Container isVisible={isVisible}>
        <ProgressBar progress={progress} className='self-start' />

        <Back
          onClick={actions.previous}
          tabIndex={-1}
          type='button'>
          <BackInner>
            <Icon
              name='chevron-up'
              className='h-5 w-5'
              title='Go to previous question' />
          </BackInner>
        </Back>
      </Container>
    </Slider>
  )
}
