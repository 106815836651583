import type { Actions } from './atom.js'
import type { Slots } from './types.js'
import type { Tables } from '^/app/store/index'
import type { Movement, OnMovementFnOptions } from '@/Assessment/types'
import Assessment, { type AssessmentProps as AssessmentProps_ } from './Assessment.js'
import useSubjectState from '#/hooks/useSubjectState'
import store from '^/app/store/store'
import useRenderContext from '#/hooks/useRenderContext'
import AssessmentHeader from './AssessmentHeader.js'
import SpinnerFixed from '@/common/SpinnerFixed'
import ErrorBoundary from '@/common/ErrorBoundary'


const Wrapper = styled.div(`
  relative
`)


type AssessmentProps<S extends Slots = EmptyObject> = Omit<AssessmentProps_<S>, 'subject' | 'subjectState'> & {
  shouldLoadFromStore?: boolean | (() => boolean)
  after?: React.ReactNode
  children?: React.ReactNode
  ref?: Dx.Ref.Prop<Actions>
}


const loading = Symbol('loading')

export default memo(function AssessmentData<S extends Slots = EmptyObject>(props: AssessmentProps<S>) {
  const {
    after,
    allowScroll,
    shouldLoadFromStore,
    children,
    ref,
    onMovement: onMovement_ = noop,
    assessment: {
      id,
    },
  } = props

  const subjectState = useSubjectState()
  const { shouldResume, isBackwardNavigation } = useAssessmentLoadState()
  const [headerState, setHeaderState] = useState({ isVisible: !shouldResume, movement: 'none' as Movement })
  const { subjectId, sessionId } = subjectState

  const shouldLoad = (isBackwardNavigation || shouldResume) && (
    typeof shouldLoadFromStore === 'function' ?
      shouldLoadFromStore() :
      shouldLoadFromStore ?? true
  )

  const [initialData, setInitialData] = useState<Tables.Assessments.Entry | typeof loading | undefined>(shouldLoad ? loading : undefined)

  useInsertionEffect(() => {
    if (shouldLoad) {
      store.assessments
        .get({ id, subjectId, sessionId })
        .then(setInitialData)
        .catch(dx.capture)
    }
  }, [store, shouldLoad, id, subjectId, sessionId])

  const onMovement = useCallback((options: OnMovementFnOptions) => {
    onMovement_(options)
    setHeaderState({ isVisible: options.stack.length <= 1, movement: options.movement })
  }, [onMovement_])

  if (initialData === loading) {
    return (
      <SpinnerFixed />
    )
  }

  return (
    <ErrorBoundary>
      <Wrapper>
        {children &&
        <AssessmentHeader
          {...headerState}
          children={children} />}

        <Assessment
          {...props}
          allowScroll={allowScroll && headerState.isVisible}
          onMovement={onMovement}
          initialData={initialData}
          subjectState={subjectState}
          ref={ref} />

        {after &&
        <AssessmentHeader
          {...headerState}
          children={after} />}
      </Wrapper>
    </ErrorBoundary>
  )
})




function useAssessmentLoadState() {
  const { urlParsed, isBackwardNavigation } = useRenderContext()
  const shouldResume = Boolean(urlParsed.search.resume)

  if (!import.meta.env.SSR && urlParsed.search.resume != null) {
    const nextUrl = new URL(window.location.href)
    nextUrl.searchParams.delete('resume')
    history.replaceState(null, '', nextUrl.toString())
  }

  return {
    shouldResume,
    isBackwardNavigation,
  }
}
