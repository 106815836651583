export * from './hooks.js'

import Thinking from './Thinking.js'
import Stream from './Stream.js'

import type { Props } from '../../types.js'
import { useAssessmentContext } from '@/Assessment/context'
import prepareInput from './prepareInput.js'
import { useLiveQuery } from 'dexie-react-hooks'
import store from '^/app/store/store'
import SpinnerBlock from '@/common/SpinnerBlock'
import useRerender from '#/hooks/useRerender'
import useDevPrompt from '@/developer/hooks/useDevPrompt'
import useDevPromptCriteria from '@/developer/hooks/useDevPromptCriteria'
import { Button } from '@/common/buttons'

export default memo((props: Props.One<'ai'>) => {
  const {
    isActive,
    invalidate,
    question: {
      id,
      tokenHash,
      name: questionName,
      attributes: {
        summarize: shouldSummarize = false,
        view,
        phase,
      },
      slots: {
        Component,
      },
    },
  } = props

  const {
    assessmentId,
    getInputTokens,
    subjectId,
    sessionId,
    summarize,
    traceId,
  } = useAssessmentContext()

  const name = `${view}:${phase?.name ?? ''}` as Dx.Ai.Model.Prompt.Name
  const [rerender, refreshCount] = useRerender()
  const [evaluating, setEvaluating] = useState(false)


  useDevPrompt(name, {
    actions: useCallback(() =>
      <Button
        size='sm'
        className='group/action'
        key='reload'
        disabled={evaluating}
        onClick={rerender}>
        Reload evaluation
      </Button>
    , [rerender, evaluating]),
  })

  const getCriteria = useDevPromptCriteria(name)

  const prepareValues = useCallback(() =>
    prepareInput({
      criteria: getCriteria(),
      tokens: getInputTokens(),
      view,
      phase,
      traceId,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [getInputTokens, view, phase, getCriteria, refreshCount, traceId])



  const [values, setValues] = useState(prepareValues)

  useEffect(() => {
    if (isActive) {
      setValues(prepareValues())
    }
  }, [prepareValues, isActive])


  const {
    isProcessingImages,
    isValid,
    input,
  } = values

  useEffect(() => {
    if (isActive && isProcessingImages) {
      const interval = setInterval(() => {
        const prepared = prepareValues()

        if (!prepared.isProcessingImages) {
          clearInterval(interval)
          setValues(prepared)
        }
      }, 250)

      return () => clearInterval(interval)
    }
  }, [prepareValues, isProcessingImages, isActive])

  const onStart = useCallback(() => {
    setEvaluating(true)

    if (shouldSummarize) {
      summarize()
    }
  }, [shouldSummarize, summarize])

  const onDone = useCallback(() => {
    setEvaluating(false)
  }, [])


  const threadId = `${assessmentId}-${questionName}-${id ?? ''}`
  const hash = `${phase?.name ?? 'default'}-${tokenHash ?? ''}`
  const key = `${hash}-${refreshCount}`
  const state = useLiveQuery(() => store.threads.where({ id: threadId, hash }).first(), [threadId, hash], null)

  if (state === null) {
    return (
      <SpinnerBlock />
    )
  }

  return (
    <Suspense>
      <Stream
        assessmentId={assessmentId}
        hash={hash}
        input={input}
        isEnabled={isActive && isValid && (invalidate || refreshCount > 0 || !state)}
        key={key}
        onStart={onStart}
        onDone={onDone}
        sessionId={sessionId}
        state={refreshCount ? undefined : state}
        subjectId={subjectId}
        threadId={threadId}
        url='/api/ai'>
        {!values.isProcessingImages &&
          <Component {...props} />}
        <Thinking isProcessingImages={values.isProcessingImages} />
      </Stream>
    </Suspense>
  )
})
