import StateMachine from '#/StateMachine/index'
import MicrophoneError from './error.js'

import {
  getAudioInput,
  hasAudioInput,
  createRecorder,
  monitorAudioAccessInput,
} from './audio.js'

import type {
  Recorder,
  Events,
} from './types.js'


const Machine = StateMachine
  .actions({
    initializing: { from: 'uninitialized', to: 'initializing' },
    ready: { from: 'initializing', to: 'ready' },
    start: { from: 'ready', to: 'started' },
    stop: { from: 'started', to: 'uninitialized' },
  })
  .name('Microphone')
  .initialState('uninitialized')
  .errorAction('stop')
  .context<{ recorder: Recorder | null }>({
    recorder: null,
  })
  .on({
    async initializing() {
      const media = await getAudioInput()
      this.set('recorder', createRecorder(media))
    },
    start() {
      this.get('recorder')?.start()
    },
    stop() {
      this.get('recorder')?.stop()
      this.set('recorder', null)
    },
  })
  .klass<Events>({ wrapError: MicrophoneError.from })


export default class Microphone extends Machine {
  static isSupported() {
    return hasAudioInput
  }

  static monitorAccess(callback: (hasAccesss: boolean) => void) {
    return monitorAudioAccessInput(callback)
  }

  async initialize() {
    if (await this.try('initializing')) {
      this
        .get('recorder', true)
        .on('dataavailable', this.emitFn('data'))
        .on('start', this.emitFn('start'))
        .on('stop', this.emitFn('stop'))
        .on('error', error => {
          this.emit('error', MicrophoneError.from(error))
          void this.stop()
        })

      return this.to('ready')
    }

    return false
  }

  async start() {
    await this.initialize()
    return this.try('start')
  }


  stop() {
    return this.try('stop')
  }
}
