import { focusFirstElement } from '#/dom/focus'
import { findAncestorMatching } from '#/dom/traversal'
import Lock from '@/FocusLock'


type FocusLockProps = {
  children: React.ReactNode,
  shouldLock?: boolean
  isActive?: boolean
  autoFocus?: boolean
  id: number
}


export default function FocusLock(props: FocusLockProps) {
  const {
    children,
    shouldLock = true,
    isActive = false,
    autoFocus = false,
  } = props


  const box = useBox(_ => ({
    container: _<'div'>(),
    edge: _<'div'>(),
    isActive,
    reset: noop,
  }))

  const [isFocused, setFocused] = useState(false)

  const onMount = useCallback((node: HTMLDivElement | null) => {
    box.container = node

    if (node) {
      const scrollable = findAncestorMatching(node, '[data-scrollable]')

      if (!scrollable) {
        return
      }

      const handler = (event: TransitionEvent) => {
        if (event.target === scrollable && box.isActive) {
          box.reset()
          startTransition(() => setFocused(true))
        }
      }

      box.reset = () => {
        scrollable.removeEventListener('transitionend', handler)
        box.reset = noop
      }

      scrollable.addEventListener('transitionend', handler)

    } else {
      box.reset()
    }
  }, [box])

  useLayoutEffect(() => {
    box.isActive = isActive

    if (!isActive) {
      setFocused(false)
    }
  }, [box, isActive])

  useLayoutEffect(() => {
    if (!shouldLock && isActive && (isFocused || autoFocus)) {
      setTimeout(() => {
        if (box.container) {
          focusFirstElement(box.container, { preventScroll: true })
        }
      }, 100)
    }
  }, [isActive, isFocused, autoFocus, shouldLock, box])

  const wrapped = (
    <div ref={onMount}>
      {children}
    </div>
  )

  return shouldLock ?
    <Lock
      children={wrapped}
      disabled={!(isActive && isFocused)} /> :
    wrapped
}
