import loader from '#/loader'
import useDevPromptCriteria from '@/developer/hooks/useDevPromptCriteria'

export default function useImageDescription() {
  const abortFn = useRef<null | (() => void)>(null)
  const getCriteria = useDevPromptCriteria('symptoms:image')

  useEffect(() => () => {
    // kills the image processing when moving to next question
    // abortFn.current?.()
  }, [])

  const describeImage = useCallback(async (url: string | null) => {
    if (!url) {
      return ''
    }

    const [load, abort] = loader<Dx.Api.Ai.Image.In, Dx.Api.Ai.Image.Out>('/api/symptoms/image', {
      method: 'post',
      body: {
        url,
        criteria: getCriteria(),
      },
    })

    abortFn.current?.()
    abortFn.current = abort

    try {
      const { description } = await load()
      return description
      
    } finally {
      abortFn.current = null
    }
  }, [getCriteria])


  return describeImage
}