import Spinner from '@/common/Spinner'
import { useTokenBoolean, useTokenDebounce } from './hooks.js'

const Message = styled.span(`
  text-gray-500
  ml-4
  align-middle
  md:text-lg
`)



type SpinnerProps = {
  isProcessingImages?: boolean
}

export default memo(({ isProcessingImages }: SpinnerProps) => {
  const isEvaluating = useTokenBoolean('meta:evaluating', null)
  const isPaused = !useTokenDebounce('*', false, {
    ms: 1000,
    isEnabled: Boolean(isEvaluating),
  })

  if (isProcessingImages) {
    return (
      <div className='mt-6'>
        <Spinner />
        <Message>Please give me a moment to analyze your photo…</Message>
      </div>
    )
  }

  if (isEvaluating) {
    return (
      <div className='mt-6'>
        {isEvaluating && <Spinner />}
        {isEvaluating && isPaused && <Message>Thinking, one moment…</Message>}
      </div>
    )
  }
})
