const Wrapper = styled.div(`
  h-0
  flex-1
`)

const Slider = styled.div(`
  w-full
  flex
  gap-3
  items-center
  motion-safe:transition-all
  overflow-hidden
  opacity-0
`)

const Track = styled.div(`
  h-[4px]
  flex-1
  bg-gray-200
  rounded-full
`)

const Bar = styled.div(`
  h-full
  bg-blue-400
  forced-colors:bg-[CanvasText]
  rounded-full
  motion-safe:transition-all
  motion-safe:duration-500
  w-0
`)

const zeroProgress: Progress = {
  total: 0,
  current: 0,
}

type Progress = {
  total: number
  current: number
}

type State = Progress & {
  visible: boolean
  transition: boolean
  width: number
}

type ProgressBarProps = {
  className?: string
  progress: Nullable<{
    current: number
    total: number
  }>
}

export default function ProgressBar(props: ProgressBarProps) {
  const { className } = props
  const next = props.progress ?? zeroProgress

  const box = useBox(_ => ({
    prev: { ...zeroProgress, transition: false, visible: false, width: 0 } as State,
    queue: [] as State[],
    slider: _<'div'>(),
    bar: _<'div'>(),
  }))

  const processNext = useCallback(() => {
    const { queue, bar, slider } = box

    if (bar && slider) {
      const state = queue.shift()

      if (state) {
        bar.style.transition = state.transition ? '' : 'none'
        bar.style.width = `${state.width}%`
        bar.offsetWidth
        slider.style.opacity = state.visible ? '1' : '0'
        slider.style.transform = state.visible ? 'translateY(0)' : 'translateY(-100%)'
        slider.style.transitionDelay = state.visible ? '0' : '200ms'
        box.prev = state

        state.transition ?
          setTimeout(processNext, 500) :
          processNext()
      }
    }
  }, [box])

  useLayoutEffect(() => {
    if (box.bar && box.slider) {
      box.queue.push(...progressState(box.prev, next))
      processNext()
    }
  }, [box, next, processNext])


  return (
    <Wrapper className={className}>
      <Slider ref={box.ref('slider')}>
        <Track>
          <Bar ref={box.ref('bar')} />
        </Track>
      </Slider>
    </Wrapper>
  )
}


function progressState(prev: State, next: Progress): State[] {
  const { total } = next
  const current = Math.min(next.current, total)

  if (prev.current === current && prev.total === total) {
    return []
  }


  // Progress is resetting, i.e. moving toward 0% or 100%.
  if (isReset(next)) {
    const nextProgress = {
      ...zeroProgress,
      transition: false,
      visible: false,
      width: 0,
    }

    // Progress reached 100%.
    if (prev.current) {
      return [
        { current: total, total, transition: true, visible: false, width: 100 },
        nextProgress,
      ]
    }

    // Progress reached 0%.
    return [
      { ...zeroProgress, transition: true, visible: false, width: 0 },
      nextProgress,
    ]
  }

  const nextProgress = {
    ...next,
    transition: true,
    visible: total > 0,
    width: getWidth(current, total),
  }

  // Progress is starting.
  if (isReset(prev)) {
    // Moving from 100% down to previous.
    if (current) {
      return [
        { current: current + 1, total, transition: false, visible: false, width: getWidth(current + 1, total) },
        nextProgress,
      ]
    }

    // Moving from 0% -> N total.
    return [
      { ...zeroProgress, transition: false, visible: false, width: 0 },
      nextProgress,
    ]
  }

  // Moving from 0% -> N current.
  return [
    nextProgress,
  ]
}


function isReset(progress: Progress) {
  return progress.current === 0 && progress.total === 0
}

function getWidth(current: number, total: number) {
  return ((current + 1) / (total + 1)) * 100
}
