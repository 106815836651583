import loader, { isAbortError, type LoaderOptions } from '#/loader'

type Events = {
  data: (chunk: string) => void
  done: () => void
  error: (error: unknown) => void
  start: () => void
}

type StreamOptions<I extends AnyRecord> = LoaderOptions<I> & {
  url: string
  onData?: Events['data']
  onDone?: Events['done']
  onError?: Events['error']
  onStart?: Events['start']
  isEnabled?: boolean
}

type Request<I extends AnyRecord> = {
  abort: () => void
  url: string
  params: LoaderOptions<I>
}

export default function useStream<I extends AnyRecord>(options: StreamOptions<I>) {
  const {
    url,
    onData: onData_,
    onError: onError_ = dx.capture,
    onDone: onDone_,
    onStart: onStart_,
    isEnabled = true,
    ...params_
  } = options

  const request = useRef<Request<I>>(null)

  const params = useMemoObject(params_)
  const onData = useEvent(onData_, true)
  const onDone = useEvent(onDone_, true)
  const onError = useEvent(onError_, true)
  const onStart = useEvent(onStart_, true)


  const abort = useCallback(() => {
    request.current?.abort?.()
    request.current = null
  }, [])


  useEffect(abort, [abort])
  useEffect(() => {
    async function execute() {
      if (request.current) {
        if (Object.isEqual(params, request.current.params)) {
          return
        }

        abort()
      }

      onStart()

      try {
        const [load, abort_] = loader<I, string>(url, {
          ...params,
          stream: true,
        })

        request.current = {
          url,
          params,
          abort: abort_,
        }

        const iterator = await load()

        for await (const chunk of iterator) {
          onData(chunk)
        }

        onDone()

      } catch (error) {
        if (!isAbortError(error)) {
          onError(error)
          abort()
        }
      }
    }

    if (isEnabled && !import.meta.env.SSR) {
      void execute()
    }
  }, [
    abort,
    isEnabled,
    onData,
    onDone,
    onError,
    onStart,
    params,
    url,
  ])


  return {
    abort,
  }
}