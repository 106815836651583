import type { Props } from '../types.js'

export default function AssessmentQuestionCustom(props: Props.One<'custom'>) {
  const {
    question: {
      slots: {
        Component,
      },
    },
  } = props

  return (
    <Component {...props} />
  )
}
