import type {
  Question,
  Slots,
} from './types.js'


import createSequence, { type Sequence } from './sequence.js'
import createHash from '#/hash'

type PrepareQuestionsOptions = {
  sequence?: Sequence
}

export function prepareQuestions<S extends Slots>(questions: readonly Question.Definition<S>[], options: PrepareQuestionsOptions = {}) {
  return prepareAll(questions, {
    sequence: options.sequence ?? createSequence(),
  })
}

export function prepareQuestion<S extends Slots>(question: Question.Definition<S>, options: PrepareQuestionsOptions = {}) {
  return prepareOne(question, {
    sequence: options.sequence ?? createSequence(),
  })
}


type PrepareOptions = {
  sequence: Sequence
}

function prepareAll<S extends Slots>(questions: readonly Question.Definition<S>[], options: PrepareOptions) {
  return questions.map(question => prepareOne(question, options))
}


function prepareOne<S extends Slots>(question: Question.Definition<S>, options: PrepareOptions): Question.Prepared.Any {
  const {
    attributes = {},
    condition,
    contextual = false,
    constrain = {},
    ephemeral = false,
    name,
    optional = false,
    progress = false,
    slots = {},
    render = {},
    text,
    trait,
    type,
  } = question

  const {
    sequence,
  } = options

  const prepared = {
    id: sequence(),
    attributes,
    condition,
    constrain,
    contextual,
    ephemeral,
    name,
    optional,
    progress,
    slotRefs: slots,
    text,
    trait,
    type,
    render: {
      shouldLockFocus: render.shouldLockFocus ?? true,
      text: render.text ?? true,
    },
  }

  return {
    ...prepared,
    hash: createHash(JSON.stringify({
      ...prepared,
      id: undefined,
      hash: undefined,
      trailHash: undefined,
    })),
  }
}
