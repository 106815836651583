import type { Traits } from '^/app/store/types'
import { useAssessmentContext } from './context.js'
import { Icon } from '@/common/index'

const TraitsWindow = styled.div(`
  h-12
  overflow-hidden
  whitespace-nowrap
  select-none
`)

const TraitsSlider = styled.div(({ hasTraits }: { hasTraits: boolean }) => [`
  inline-flex
  flex-1
  group
  motion-safe:transition-all
  motion-safe:duration-400
  text-sm
`, {
  [`
    opacity-100
  `]: hasTraits,
  [`
    -translate-y-2
    opacity-0
  `]: !hasTraits,
}])

const TraitsContainer = styled.div(`
  flex
  items-start
  text-sky-700
  pt-3
`)

const TraitsLabel = styled.div(`
  px-3
  py-1
  bg-blue-50
  forced-colors:border-1
  rounded-lg
  flex
  items-center
  gap-3
  z-10
`)

const TraitsDivider = styled.div(`
  h-2
  w-[1px]
  bg-sky-700
  forced-colors:border-l-1
  shrink-0
`)

const ClearWindow = styled.div(({ isClearing }: { isClearing: boolean }) => [`
  self-stretch
  flex-1
  flex
  items-start
  opacity-0
  motion-safe:transition-all
  -translate-x-full
`,
{
  [`
    group-hover:translate-x-0
    group-hover:opacity-100
  `]: !isClearing,
}])

const ClearSlider = styled.button(`
  text-gray-500
  group/button
  hover:text-black
  cursor-pointer
  relative
  pl-1
  w-28
  self-stretch
  flex
  items-start
  -mt-2 pt-2
`)

const ClearButton = styled.div(`
  relative
  z-0
  flex
  items-center
  gap-1
  bg-gray-50
  rounded-lg
  py-1
  px-3
  w-fit
`)



export default function TraitsComponent() {
  const {
    traits: {
      current: traits_,
      clear,
    },
  } = useAssessmentContext()

  const [pendingTraits, setPendingTraits] = useState<Partial<Traits.ByName>>({})
  const [visibleTraits, setVisibleTraits] = useState<Partial<Traits.ByName>>({})
  

  useEffect(() => {
    setPendingTraits(traits_)

    if (Object.values(traits_).length > 0) {
      setVisibleTraits(traits_)

    } else {
      const timeout = setTimeout(() => setVisibleTraits({}), 800)
      return () => clearTimeout(timeout)
    }
  }, [traits_])


  const label = [
    visibleTraits.age?.value,
    visibleTraits.sex?.value?.toUpperFirst(),
  ].compact()

  const hasTraits = Object.values(visibleTraits).length > 0
  const isClearing = hasTraits && Object.values(pendingTraits).length === 0

  return (
    <TraitsWindow>
      <TraitsSlider hasTraits={hasTraits && !isClearing}>
        {hasTraits && label.length > 0 &&
          <TraitsContainer>
            <TraitsLabel>
              {label.compact().interpose(i => <TraitsDivider key={`divider-${i}`} />)}
            </TraitsLabel>

            <ClearWindow isClearing={isClearing}>
              <ClearSlider
                type='button'
                onClick={() => void clear()}>

                <ClearButton>
                  <Icon
                    name='x'
                    className='group-hover/button:text-blue-500' />
                  Clear
                </ClearButton>
              </ClearSlider>
            </ClearWindow>
          </TraitsContainer>}
      </TraitsSlider>
    </TraitsWindow>
  )
}
