function monitor(query: string) {
  if (import.meta.env.SSR) {
    return {
      matches: false,
      watch: noop,
    }
  }

  const media = window.matchMedia(query)
  const callbacks: Array<(matches: boolean) => void> = []

  const output = {
    matches: media.matches,
    watch(fn: (matches: boolean) => void) {
      callbacks.push(fn)
      fn(output.matches)

      return () => {
        callbacks.pull(fn)
      }
    },
  }

  media.addEventListener('change', () => {
    const { matches } = media
    output.matches = matches
    callbacks.forEach(fn => fn(matches))
    console.log(media.media, media.matches)
  })

  return output
}


export const reducedMotion = monitor('(prefers-reduced-motion: reduce)')
