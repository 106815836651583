import StateMachine from './StateMachine.js'
import EventEmitter, { type Events } from '#/EventEmitter'

import type {
  ActionName,
  Actions,
  ErrorActionName,
  FactoryAction,
  FactoryActions,
  FactoryContext,
  FactoryContextArgs,
  InitializedActions,
  InitializedContext,
  Properties,
  States,
  Transitions,
} from './types.js'


export default class StateMachineFactory<const A extends Actions, const X extends FactoryContext, const E extends ErrorActionName<A>, const T extends Transitions<A, InitializedContext<X>, E>> {
  static actions<const A_ extends FactoryActions>(actions: A_) {
    type AI = InitializedActions<A_>

    const initializedActions = Object.mapValues(actions, (action, name) => ({
      from: castFromState(action.from),
      to: action.to,
      name,
    })) as AI

    const initialState = [...Object.values(initializedActions)[0].from][0] as States<AI>

    return new StateMachineFactory({
      actions: initializedActions,
      initialState,
      transitions: {},
      context: {},
      errorAction: null,
      name: 'StateMachine',
    })
  }

  #properties: Properties<A, X, E, T>

  private constructor(properties: Properties<A, X, E, T>) {
    this.#properties = properties
  }

  initialState<S_ extends States<A>>(initialState: S_) {
    return new StateMachineFactory({
      ...this.#properties,
      initialState,
    })
  }

  name(name: string) {
    return new StateMachineFactory({
      ...this.#properties,
      name,
    })
  }

  context<const X_ extends FactoryContext>(context: X_) {
    return new StateMachineFactory({
      ...this.#properties,
      context,
    })
  }

  on<const T_ extends Transitions<A, InitializedContext<X>, E>>(transitions: T_) {
    return new StateMachineFactory({
      ...this.#properties,
      transitions,
    } as unknown as Properties<A, X, E, T_>)
  }

  errorAction<const E_ extends ActionName<A>>(errorAction: E_) {
    return new StateMachineFactory({
      ...this.#properties,
      errorAction,
    } as unknown as Properties<A, X, E_, Transitions<A, InitializedContext<X>, E_>>)
  }

  build(...args: FactoryContextArgs<X>) {
    const context = typeof this.#properties.context === 'function' ?
      this.#properties.context(...args) :
      this.#properties.context

    return new StateMachine({
      ...this.#properties,
      context: context as InitializedContext<X>,
    })
  }

  klass<const K extends Events>({ wrapError }: { wrapError?: (error: unknown) => unknown } = {}) {
    const machine = this

    return class extends EventEmitter<K> {
      #machine: StateMachine<A, InitializedContext<X>, E, T>
      #wrapError: (error: unknown) => unknown

      constructor(...args: FactoryContextArgs<X>) {
        super()
        this.#wrapError = wrapError ?? identity
        this.#machine = machine.build(...args)
        this.#machine.on('error', (error: unknown) => this.emit('error', ...[this.#wrapError(error)] as Parameters<K['error']>))
      }

      protected get machine() {
        return this.#machine
      }

      protected try<N extends ActionName<A>>(name: N) {
        return this.#machine.try(name)
      }

      protected to<N extends ActionName<A>>(name: N) {
        return this.#machine.to(name)
      }

      get<const P extends string>(path: P, require: true): NonNullable<Get<Writable<InitializedContext<X>>, P>>
      get<const P extends string>(path: P, require?: false): Get<Writable<InitializedContext<X>>, P>
      get<const P extends string>(path: P, require = false) {
        return this.#machine.get(path, require as any)
      }

      protected abort() {
        return this.#machine.abort()
      }

      protected is(state: States<A> & string) {
        return this.#machine.is(state)
      }
    }
  }
}



function castFromState<S extends FactoryAction['from']>(state?: S): Set<string> {
  if (typeof state === 'string') {
    return new Set([state])
  }

  if (Array.isArray(state)) {
    return new Set(state as string[])
  }

  if (state instanceof Set) {
    return state
  }

  return new Set(['*'])
}