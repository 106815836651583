import MicrophoneError from './error.js'

export type Recorder = ReturnType<typeof createRecorder>

export const hasAudioInput = hasAudioInput_()

export async function getAudioInput() {
  try {
    return await navigator.mediaDevices.getUserMedia({ audio: true })

  } catch (error) {
    throw MicrophoneError.from(error)
  }
}


export async function monitorAudioAccessInput(callback: (hasAccess: boolean) => void) {
  if (import.meta.env.SSR || !(await hasAudioInput)) {
    callback(false)
    return noop
  }

  if ('query' in navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name: 'microphone' as PermissionName })

      callback(result.state !== 'denied')
      const handler = () => callback(result.state !== 'denied')
      result.addEventListener('change', handler)
      return () => result.removeEventListener('change', handler)

    } catch (e) {}
  }

  callback(true)
  return noop
}



async function hasAudioInput_() {
  if (import.meta.env.SSR) {
    return true
  }
  
  if (!navigator.mediaDevices?.enumerateDevices) {
    return false
  }

  try {
    const devices = await navigator.mediaDevices.enumerateDevices()
    return devices.some(device => device.kind === 'audioinput')
    
  } catch (error) {
    dx.capture(error)
    return false
  }
}




export function createRecorder(media: MediaStream) {
  const recorder = new MediaRecorder(media)
  let listeners = [] as [string, AnyFunction][]


  const self = {
    on<K extends keyof MediaRecorderEventMap>(type: K, listener: (this: MediaRecorder, event: MediaRecorderEventMap[K]) => unknown) {
      recorder.addEventListener(type, listener)
      listeners.push([type, listener] as const)
      return self
    },

    start(timeSlice?: number) {
      if (recorder.state === 'inactive' || recorder.state === 'paused') {
        recorder.start(timeSlice ?? 500)
        return true
      }

      return false
    },

    stop() {
      if (recorder.state === 'paused' || recorder.state === 'recording') {
        recorder.stop()
        listeners.forEach(([type, listener]) => recorder.removeEventListener(type, listener))
        listeners = []

        const { stream } = recorder
        
        stream.getTracks().forEach(track => {
          track.stop()
          stream.removeTrack(track)
        })

        return true
      }

      return false
    },
  }

  return self
}