import type {
  Assessment as Assessment_,
  Slots,
  OnMovementFnOptions,
} from './types.js'

import {
  useAtom,
  type Actions,
} from './atom.js'

import { AssessmentContext } from './context.js'
import uuid from '#/uuid'

import {
  store,
  type Traits,
  type Datum,
  type Tables,
} from '^/app/store/index'

import loader from '#/loader'
import { chunkToTokens } from '#/ai/tokens/tokenChunks'
import prepareInput from './types/ai/prepareInput.js'
import useKeyBindings from './useKeyBindings.js'
import { prepareQuestions } from './prepare.js'
import { stripSlot } from './slots.js'
import Scroller from './Scroller.js'
import type { SubjectState } from '#/hooks/useSubjectState'
import useBreadcrumbs from '#/hooks/useBreadcrumbs'
import { getInput } from './getInput.js'
import Controls from './Controls.js'
import useDevPromptCriteria from '@/developer/hooks/useDevPromptCriteria'
import useInDevMode from '@/developer/hooks/useInDevMode'



const Wrapper = styled.div(`
  relative
`)

export type AssessmentProps<S extends Slots> = {
  allowScroll?: boolean
  assessment: Assessment_<S>
  className?: string
  context?: UnknownRecord<string>
  defaultSnippet?: string
  disableStore?: () => boolean
  group?: Tables.Breadcrumbs.Entry['group']
  initialData?: Tables.Assessments.Entry
  onMovement?: (options: OnMovementFnOptions) => void
  subjectState: SubjectState
  prefillDatums?: Datum[]
  ref?: Dx.Ref.Prop<Actions>
}



export default memo(<S extends Slots = EmptyObject>(props: AssessmentProps<S>) => {
  const {
    allowScroll = false,
    assessment: {
      id,
      title,
      snippetName,
      slots: slots_,
      questions: questionDefinitions,
    },
    className,
    context: context_,
    defaultSnippet,
    group = 'assessment',
    subjectState,
    initialData,
    prefillDatums,
    ref,
  } = props

  const onMovement = useEvent(props.onMovement, true)
  const slots = useMemoObject(slots_)

  const {
    sessionId,
    subjectId,
    isLoading: isSubjectLoading,
    traits: subjectTraits,
  } = subjectState


  const data = useMemo(() => {
    const questions = initialData?.questions ?? prepareQuestions(questionDefinitions)
    const datums = [...initialData?.datums ?? Array._]
    const skipQuestionNames = new Set<string>()

    if (context_) {
      datums.unshift({
        name: 'context',
        value: context_,
        source: 'default',
        sessionId: subjectState.sessionId,
        subjectId: subjectState.subjectId,
      })
    }

    if (prefillDatums) {
      datums.unshift(...prefillDatums)
      skipQuestionNames.addAll(...datums.mapBy('name'))
    }

    return {
      datums,
      questions,
      snippet: initialData?.snippet ?? defaultSnippet,
      stack: initialData?.stack,
      summary: initialData?.summary,
      assessmentId: id,
      sessionId: subjectState.sessionId,
      skipQuestionNames,
      slots,
      snippetName,
      subjectId: subjectState.sessionId,
      traceId: initialData?.traceId ?? uuid(),
    }
  }, [
    context_,
    initialData,
    defaultSnippet,
    prefillDatums,
    questionDefinitions,
    subjectState,
    slots,
    snippetName,
    id,
  ])


  const [state, actions] = useAtom(data)

  const {
    datums,
    invalidate,
    movement,
    question,
    questions,
    snippet,
    stack,
    summary,
    progress,
    traitDatums: traitDatums_,
    traceId,
  } = state

  useLayoutEffect(() => {
    if (traitDatums_ === null && !isSubjectLoading) {
      actions.setTraits(subjectTraits.map(t => ({ ...t.datum, external: true })))
    }
  }, [traitDatums_, isSubjectLoading, actions]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    onMovement({
      question,
      stack,
      movement,
    })
  }, [question, movement, stack, onMovement])

  useKeyBindings(actions)
  useImperativeHandle(ref, () => actions, [actions])

  const getInputTokens = useEvent(() => getInput({ datums, stack }).tokens)

  const inDevMode = useInDevMode()
  const getCriteria = useDevPromptCriteria('symptoms:chart')

  const summarize = useCallback(async () => {
    if (!inDevMode) {
      return
    }
    
    const output: UnknownRecord<string> = {}

    const [load, abort] = loader<Dx.Api.Ai.In, string>('/api/ai', {
      method: 'post',
      stream: true,
      body: prepareInput({
        criteria: getCriteria(),
        tokens: getInputTokens(),
        view: 'symptoms',
        traceId,
        phase: {
          name: 'chart',
        },
      }).input,
    })

    load()
      .then(async iterator => {
        for await (const chunk of iterator) {
          chunkToTokens(chunk).forEach(token => {
            Object.set(output, token.key, token.value)
          })
        }
      })
      .catch(dx.capture)

    return abort
  }, [getInputTokens, getCriteria, inDevMode, traceId])

  
  const traits = useMemo(() => ({
    current: (traitDatums_ ?? Array._).keyBy('name') as Traits.ByName,
    async clear() {
      actions.clearTraits()
      await store.traits.clear()
    },
  }), [traitDatums_, actions])

  const context = useMemo(() => ({
    actions,
    assessmentId: id,
    getInputTokens,
    sessionId,
    subjectId,
    getInput,
    summarize,
    traceId,
    traits,

  }), [
    actions,
    id,
    getInputTokens,
    sessionId,
    subjectId,
    summarize,
    traceId,
    traits,
  ])

  const nextState: Tables.Assessments.Entry = useMemoObject({
    datums,
    id,
    questions: questions.map(stripSlot),
    sessionId,
    snippet,
    stack,
    subjectId,
    summary,
    title,
    traceId,
    url: dx.url.current().href,
  })

  const crumbs = useBreadcrumbs()

  useEffect(() => {
    store
      .assessments
      .put(nextState)
      .catch(dx.capture)

    if (nextState.stack.length > 1 && nextState.snippet) {
      crumbs.add({
        group,
        key: String(nextState.id),
        title: nextState.title,
        snippet: nextState.snippet,
        params: {
          resume: true,
        },
      }).catch(dx.capture)
    }
  }, [nextState, crumbs, group])

  useEffect(() => {
    const updates: Tables.Traits.Entry[] = datums
      .filter(isTrait)
      .map(datum => ({
        id: datum.traitId,
        name: datum.name,
        subjectId,
        datum,
      }))

    store.traits.bulkPut(updates).catch(dx.capture)
  }, [datums, subjectId])

  return (
    <>
      <Controls
        actions={actions}
        progress={progress}
        isVisible={stack.length > 1} />

      <Wrapper className={className}>
        <AssessmentContext.Provider value={context}>
          <Scroller
            actions={actions}
            allowScroll={allowScroll}
            datums={datums}
            movement={movement}
            invalidate={invalidate}
            question={question} />
        </AssessmentContext.Provider>
      </Wrapper>
    </>
  )
})

function isTrait(datum: Datum): datum is SetRequired<Datum, 'traitId'> {
  return Boolean(datum.traitId)
}
