import QuestionComponent from './Question.js'
import type { Actions } from './atom.js'
import type { Datum } from '^/app/store/index'
import type { Movement, Question } from './types.js'
import { type Transition, watchTransition } from '#/transitions'
import { reducedMotion } from '#/dom/media'

const Container = styled.div(({ allowScroll }: { allowScroll: boolean }) => ({
  className: [`
    grid
    z-0
    overflow-x-hidden
  `, {
    'overflow-y-auto': allowScroll,
    'overflow-y-hidden': !allowScroll,
  }],
  style: {
    scrollbarWidth: 'none',
    gridTemplateColumns: '1fr',
  },
}))


const Scrollable = styled.div(({ offset }: { offset: number }) => ({
  className: [`
    motion-safe:transition-all
    motion-safe:duration-500
    h-fit
    row-start-1
    col-start-1
    min-h-16
  `, {
    [`
      opacity-100
      overflow-y-auto
    `]: offset === 0,
    [`
      opacity-0
      overflow-y-hidden
      select-none
      pointer-events-none
    `]: offset !== 0,
  }],
  style: {
    scrollbarGutter: 'stable',
    scrollbarWidth: 'thin',
    transform: `translateY(${offset * 100}%)`,
  },
}))



type ScrollerProps = {
  actions: Actions
  allowScroll: boolean
  datums: Datum[]
  invalidate: boolean
  movement: Movement
  question: Question.Any | null
}


type ScrollState = {
  pointer: number
  nextPointer: number | null
  questions: Array<Question.Any | null>
  locked: Question.Any[]
  autoFocus: boolean
}

export default function Scroller(props: ScrollerProps) {
  const {
    actions,
    allowScroll,
    datums,
    invalidate,
    movement,
    question: next,
  } = props

  const [display, setDisplay] = useState<ScrollState>({
    pointer: 0,
    nextPointer: null as number | null,
    questions: [next],
    locked: [],
    autoFocus: true,
  })

  const box = useBox(_ => ({
    current: _<'div'>(),
    container: _<'div'>(),
    height: 250,
    transition: null as Transition | null,
  }))


  useEffect(() => {
    setDisplay(d => getNextState(d, next, movement))
  }, [box, next, movement])


  useEffect(() => {
    if (display.nextPointer == null) {
      return
    }

    box.container?.scrollIntoView({
      behavior: reducedMotion.matches ? 'instant' : 'smooth',
      block: 'start',
    })

    setDisplay(d => ({
      questions: d.questions,
      locked: d.locked,
      pointer: d.nextPointer!,
      nextPointer: null,
      autoFocus: false,
    }))


    box.transition?.expected()
  }, [display.nextPointer, box])


  const lock = useCallback((id: number, shouldLock = true) => {
    setDisplay(d => {
      const locked = d.locked.find(q => q.id === id)

      if (shouldLock) {
        const question = d.questions.find(q => q?.id === id)

        return question && !locked ?
          { ...d, locked: [...d.locked, question] } :
          d
      }

      if (locked) {
        return {
          ...d,
          locked: d.locked.filter(q => q.id !== id),
        }
      }

      return d
    })
  }, [])

  const onMount = useCallback((element: HTMLDivElement | null) => {
    box.container = element
    box.transition?.detatch()
    box.transition = null

    if (element) {
      box.transition = watchTransition(element, {
        propertyName: 'transform',
        selector: '[data-scrollable]',
        fn: () => setDisplay(d => getNextState(d, d.questions[d.pointer] ?? null, 'none')),
      })

    }
  }, [box])


  const locked = display.locked.filter(l => !display.questions.some(q => q?.id === l.id))
  const render = [...locked, ...display.questions]
  const pointer = locked.length + display.pointer


  useEffect(() => () => {
    box.transition?.detatch()
  }, [box])


  return (
    <Container
      ref={onMount}
      data-scroller
      allowScroll={allowScroll}>
      {render.map((question, index) => {
        if (!question) {
          return null
        }

        const offset = index - pointer
        const value = datums.find(d =>
          d.refId === question.id &&
          d.name === question.name
        )?.value

        return (
          <Scrollable
            offset={offset}
            key={question.id}
            data-scrollable
            data-question={question.id}
            suppressHydrationWarning
            ref={offset === 0 ? box.ref('current') : undefined}>
            <QuestionComponent
              actions={actions}
              autoFocus={display.autoFocus}
              invalidate={offset === 0 && invalidate}
              isActive={offset === 0}
              lock={lock}
              key={question.id}
              question={question}
              value={value} />
          </Scrollable>
        )
      })}
    </Container>
  )
}




function getNextState(state: ScrollState, next: Question.Any | null, movement: Movement) {
  let {
    questions,
    pointer,
    nextPointer,
    locked,
  } = state

  const current = questions[pointer]

  // Immediately jump to the question and clear the queue.
  if (current?.id === next?.id || movement === 'none') {
    questions = [next]
    pointer = 0
    nextPointer = null

  } else {
    // Ensure the question is at the end of the queue.
    if (movement === 'next') {
      questions = questions.slice(0, pointer + 1).pull(next)
      questions.push(next)

    // Ensure the question is at the beggining of the queue.
    } else {
      questions = questions.slice(pointer).pull(next)
      questions.unshift(next)
    }

    // Reset the pointers.
    pointer = questions.positionOf(current) ?? pointer
    nextPointer = questions.positionOf(next)
  }

  return {
    questions,
    pointer,
    nextPointer,
    locked,
    autoFocus: false,
  }
}
