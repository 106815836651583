import styles from './styles.module.css'
import useVoice from './Voice/useVoice.js'
import type { VoiceState } from './Voice/types.js'
import useSanitizedValue from './useSanitizedValue.js'
import useImage, { type UseImageOptions } from '#/hooks/useImage'

import {
  Button,
  Icon,
  Spacer,
  input,
} from '@/common/index'


const Container = styled.div([
  input,
  'flex',
  'flex-col',
])

const InputContainer = styled.div([styles.inputContainer, `
  px-4
  py-3
  lg:px-5
  lg:py-4
  relative
  focus:ring-0
  outline-0
  flex-1
`])



const Placeholder = styled.div(({ initiallyHidden }: { initiallyHidden?: boolean }) => ({
  attributes: {
    'aria-hidden': initiallyHidden,
    hidden: initiallyHidden,
  },
  className: `
    absolute
    text-gray-500
    select-none
    pointer-events-none
    md:text-lg
    inset-x-4
    lg:inset-x-5
  `,
}))

const Editor = styled.div(({ rows }: { rows?: number }) => ({
  style: ({ minHeight: `${rows ?? 2}lh` }),
  className: [styles.editor, `
    bg-transparent
    caret-blue-600
    focus:ring-0
    outline-0
    md:text-lg
  `],
}))

const Cursor = styled.div([`
  absolute
  h-[1lh]
  bg-blue-600
  h-[22px]
  top-[-100px]
`])

const CursorLine = styled.div([styles.cursor, `
  absolute
  h-full
  w-[2px]
  bg-blue-600
  rounded-[1px]
`])

const Indicator = styled.div([styles.indicator, `
  absolute
  top-[26px]
  left-[-14px]
  w-[30px]
  py-1
  flex
  justify-center
  text-center
  rounded-[14px]
  text-white
  leading-none
  bg-blue-600
  shadow-md
  hide
`])

const EditorHiddenInput = styled.textarea(`
  absolute
  opacity-0
  inset-0
  pointer-events-none
`)



const Images = styled.div(`
  px-3
  py-2
  border-gray-300
  flex
  gap-5
`)

const ImageWrapper = styled.div(`
  relative
`)

const Image = styled.img(`
  max-h-[100px]
  rounded-lg
`)

const ImageRemoveButton = styled.button(`
  absolute
  -right-2
  -top-2
  text-white
  h-6
  w-6
  bg-gray-800
  rounded-full
  hover:bg-rose-600
  cursor-pointer
  flex
  items-center
  justify-center
`)

const ImageFileInput = styled.input(`
  absolute
  m-0
  p-0
  inset-0
  opacity-0
  text-[0]
`)


const Buttons = styled.div(`
  inset-x-0
  bottom-0
  flex
  py-3
  px-2
  md:px-3
  md:py-4
  lg:px-4
  lg:py-5
  items-center
  gap-1
  xs:gap-2
  md:gap-3
`)

const MicrophonePopover = styled.div(({ isVisible }: { isVisible?: boolean }) => [
  styles.micPopover,
  `
  absolute
  top-[100%]
  right-1/2
  text-xs
  w-72
  sm:text-sm
  sm:w-96
  rounded-xl
  shadow-md
  z-15
  translate-x-1/2
  text-center
  p-2
  bg-rose-50
  border
  border-rose-100
  text-pink-900
  select-none
  `,
  {
    'block': isVisible,
    'hidden group-hover:block': !isVisible,
  },
])

type TextareaProps = {
  autoFocus?: boolean
  children?: React.ReactNode
  className?: string
  defaultValue?: string
  image?: UseImageOptions & { name?: string }
  name?: string
  onChange?: (value: string) => void
  onKeyDown?: (event: Dx.Event.Keyboard<'div'>) => void
  onKeyUp?: (event: Dx.Event.Keyboard<'div'>) => void
  onBlur?: (event: Dx.Event.Focus<'div'>) => void
  onEnter?: (event: Dx.Event.Keyboard<'div'>) => void
  onFocus?: (event: Dx.Event.Focus<'div'>) => void
  onImageDescription?: (description: string | null) => void
  onInput?: (event: Dx.Event.Keyboard<'div'>) => void
  onSpeakingChange?: (isSpeaking: boolean) => void
  onTranscript?: (transcript: string) => void
  onStateChange?: (state: VoiceState) => void
  placeholder?: string
  required?: boolean | 'unlessImage'
  rows?: number
  voice?: boolean
}


const readyStates = new Set([
  'initializing',
  'ready',
  'active',
])

const indicatorStates = new Set([
  'ready',
  'active',
])

type Actions = {
  setVoiceState: (nextState: VoiceState) => void
  setVoiceActive: (nextActive: boolean) => void
  toggleVoiceActive: () => void
  toggleMicrophonePopover: () => void
}

const useMatter = Matter
  .state({
    isVoiceActive: false,
    showMicrophonePopover: false,
    voiceState: 'inactive' as VoiceState,
    stateChangedMs: -1,
  })
  .actions<Actions>({
    setVoiceState(state, nextState) {
      if (state.voiceState === nextState) {
        return
      }
      
      state.voiceState = nextState
      state.stateChangedMs = Date.now()

      if (nextState === 'inactive' || nextState === 'finalizing') {
        state.isVoiceActive = false
      }
    },

    setVoiceActive(state, nextActive) {
      state.isVoiceActive = nextActive
      state.stateChangedMs = Date.now()
    },

    toggleVoiceActive(state) {
      if (Date.now() - 500 > state.stateChangedMs) {
        this.setVoiceActive(!state.isVoiceActive)
      }
    },

    toggleMicrophonePopover(state) {
      state.showMicrophonePopover = !state.showMicrophonePopover
    },
  })


export default memo(function Textarea(props: TextareaProps) {
  const {
    autoFocus = false,
    children,
    className,
    image,
    name,
    placeholder: placeholderText = '',
    rows = 4,
    required,
    onFocus,
    onImageDescription: onImageDescription_,
    onSpeakingChange,
    onTranscript,
    voice: isVoiceEnabled_ = true,
  } = props


  const [{ isVoiceActive, showMicrophonePopover }, actions] = useMatter()
  const onImageDescription = useEvent(onImageDescription_)
  const [currentImage, imageActions, { accept }] = useImage({
    ...image,
    onDescription: onImageDescription,
  })

  const defaultValue = useSanitizedValue(props.defaultValue)
  const onChange = useEvent(props.onChange)
  const onEnter = useEvent(props.onEnter)
  const timers = useTimers()
  

  const box = useBox(_ => ({
    container: _<'div'>(),
    cursor: _<'div'>(),
    editor: _<'div'>(),
    image: _<'input'>(),
    indicator: _<'div'>(),
    isIndicatorVisible: false,
    isPlaceholderInitiallyHidden: Boolean(defaultValue),
    isSpeaking: false,
    placeholder: _<'div'>(),
    state: 'inactive' as VoiceState,
    textarea: _<'textarea'>(),
    value: defaultValue,
  }))

  useEffect(() => {
    if (!defaultValue && box.value) {
      box.value = ''
      box.placeholder?.removeAttribute('hidden')
    }
  }, [defaultValue, box])

  useLayoutEffect(() => {
    if (box.editor) {
      box.editor.innerHTML = defaultValue

      // Fix for Firefox
      if (box.editor.contentEditable !== 'plaintext-only') {
        box.editor.contentEditable = 'true'
      }
    }
  }, [box]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateDisplay = useCallback(() => {
    const {
      placeholder,
      editor,
      textarea,
      container,
      indicator,
      isIndicatorVisible,
      state,
    } = box

    if (container && indicator) {
      container.classList.toggle(styles.transcribing, readyStates.has(state))
      indicator.classList.toggle(styles.hide, !isIndicatorVisible)
    }
      
    const text = editor?.innerText ?? ''
    placeholder?.toggleAttribute('hidden', !(text === '' || text === '\n'))

    const value = text.trim()
    onChange(value)

    if (textarea) {
      textarea.value = box.value = value
    }
  }, [box, onChange])
 

  const voice = useVoice({
    url: __appVoiceUrl__,
    isActive: isVoiceActive,
    getContainerRect: () => box.container?.getBoundingClientRect() ?? null,
    onCursorMove({ left, top }) {
      if (box.cursor) {
        box.cursor.style.top = `${top}px`
        box.cursor.style.left = `${left}px`
      }
    },
    onSpeakingChange(isSpeaking) {
      box.isIndicatorVisible = !isSpeaking
      updateDisplay()
      onSpeakingChange?.(isSpeaking)
    },
    onStateChange(state) {
      box.state = state
      box.isIndicatorVisible = indicatorStates.has(state)
      updateDisplay()
      actions.setVoiceState(state)
    },
    onTranscript(transcript) {
      updateDisplay()
      onTranscript?.(transcript)
    },
  })

  const didHaveAccess = useRef(voice.hasAccess)

  useEffect(() => {
    if (!voice.hasAccess && didHaveAccess.current != null && didHaveAccess.current !== voice.hasAccess) {
      actions.toggleMicrophonePopover()
      timers.name('popover').delay(actions.toggleMicrophonePopover, 3_000)
    }

    didHaveAccess.current = voice.hasAccess
  }, [voice.hasAccess, actions, timers])

  const isVoiceEnabled = Boolean(isVoiceEnabled_ && __appVoiceUrl__)
  const abort = useCallback(() => void voice.stop(), [voice])
  const onInput = useMergedEvent(abort, props.onInput, updateDisplay)
  const onBlur = useMergedEvent(abort, props.onBlur)
  const onKeyUp = useMergedEvent(abort, props.onKeyUp)
  const onKeyDown = useMergedEvent(abort, (event: Dx.Event.Keyboard<'div'>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      return onEnter(event)
    }
  }, props.onKeyDown)

  const onUploadImage = useCallback((event: Dx.Event.Change<HTMLInputElement>) => {
    box.editor?.focus()
    const file = event.target?.files?.[0]
    void imageActions.set(file)
  }, [box, imageActions])

  const onRemoveImage = useCallback(() => {
    box.editor?.focus()
    imageActions.reset()

    if (box.image) {
      box.image.value = ''
    }
  }, [box, imageActions])


  useEffect(() => {
    isVoiceActive && box.editor?.focus()
  }, [isVoiceActive, box])

  // TODO: handle currentImage.error
  
  return (
    <Container
      className={classNames(className, styles.container)}
      ref={box.ref('container')}>

      {image?.name &&
      <>
        <input
          type='hidden'
          name={image.name}
          value={currentImage.url ?? ''} />

        <input
          type='hidden'
          name={`${image.name}Description`}
          value={currentImage.description ?? ''} />
      </>}

      <EditorHiddenInput
        ref={box.ref('textarea')}
        name={name}
        required={required === 'unlessImage' ? !currentImage?.url : required}
        tabIndex={-1}
        defaultValue={defaultValue}
        aria-hidden='true' />

      <InputContainer>
        <Placeholder
          className={styles.placeholder}
          initiallyHidden={box.isPlaceholderInitiallyHidden}
          ref={box.ref('placeholder')}
          tabIndex={-1}>
          {placeholderText}
        </Placeholder>

        <Editor
          aria-label={placeholderText}
          autoFocus={autoFocus}
          className={styles.editor}
          contentEditable='plaintext-only'
          onBlur={onBlur}
          onClick={abort}
          onFocus={onFocus}
          onInput={onInput}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          ref={box.ref('editor')}
          role='textbox'
          rows={rows}
          suppressHydrationWarning
          tabIndex={0} />


        <Cursor ref={box.ref('cursor')}>
          <CursorLine />

          <Indicator ref={box.ref('indicator')}>
            <Icon name='mic' />
          </Indicator>
        </Cursor>
      </InputContainer>

      {image && currentImage.previewUrl &&
        <Images>
          <ImageWrapper>
            <ImageRemoveButton
              type='button'
              onClick={onRemoveImage}>
              <Icon name='x' title='Remove image' />
            </ImageRemoveButton>

            <Image
              src={currentImage.previewUrl}
              alt='A preview thumbnail of the image you uploaded.' />
          </ImageWrapper>
        </Images>}

      <Buttons>
        {image &&
          <Button
            type='button'
            aria-label='Upload a photo'
            className='flex items-center gap-2'>
            <ImageFileInput
              aria-hidden='true'
              accept={accept.join(',')}
              type='file'
              onChange={onUploadImage}
              className='cursor-pointer'
              ref={box.ref('image')}
              tabIndex={-1} />
            <span aria-hidden='true' className='hidden sm:inline-block'>Upload <span className='hidden md:inline-block'>a</span> photo</span>
            <span aria-hidden='true' className='hidden 2xs:inline-block sm:hidden'>Photo</span>
            <Icon name='image' />
          </Button>}

        {isVoiceEnabled &&
          <div className='relative group' suppressHydrationWarning={true}>
            <Button
              type='button'
              className='flex items-center gap-2'
              isActive={isVoiceActive}
              isDisabled={!voice.isSupported || !voice.hasAccess}
              onClick={actions.toggleVoiceActive}
              aria-label='Upload a photo'
              suppressHydrationWarning={true}>
              <span aria-hidden='true' className='hidden sm:inline-block'>Use your voice</span>
              <span aria-hidden='true' className='hidden 2xs:inline-block sm:hidden'>Voice</span>
              <Icon name='mic' />
            </Button>

            {!voice.hasAccess &&
              <MicrophonePopover
                suppressHydrationWarning={true}
                isVisible={showMicrophonePopover}>
                DxCheck needs your permission to use your microphone. Check your browser settings.
              </MicrophonePopover>}
          </div>}

        <Spacer />
        {children}
      </Buttons>
    </Container>
  )
})