import type {
  Props,
} from '../types.js'

import {
  Buttons,
  ButtonPrimary,
  Icon,
} from '@/common/index'

import Textarea from '@/Textarea/index'

export default function AssessmentQuestionTextarea(props: Props.One<'textarea'>) {
  const {
    autoFocus,
    value,
    submit,
    question: {
      name,
      text,
      optional,
      attributes: {
        placeholder,
        rows,
      },
    },
  } = props


  const onEnter = useCallback((event: Dx.Event.Keyboard<'div'>) => {
    event.preventDefault()
    event.stopPropagation()
    submit()
  }, [submit])

  return (
    <>
      <Textarea
        name={name}
        autoFocus={autoFocus}
        defaultValue={value ?? ''}
        placeholder={placeholder === true ? text : placeholder}
        required={!optional}
        onEnter={onEnter}
        rows={rows} />
        
      <Buttons>
        <div className='flex-auto' />
        <ButtonPrimary type='submit'>
          Continue
          <Icon name='arrow-right' className='ml-2' />
        </ButtonPrimary>
      </Buttons>
    </>
  )
}
