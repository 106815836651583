import { wrappedError } from '#/errors'

type ErrorReason =
  'connectionFail' |
  'server' |
  'api' |
  'unknown'


export default wrappedError('VoiceSocketError', error => {
  if (Object.isObject(error) && 'type' in error) {
    return error.type as ErrorReason
  }

  return 'unknown'
})
