import FocusLock from './FocusLock.js'
import * as types from './types/index.js'
import type { Props } from './types.js'


const Form = styled.form(`
  pl-[2px]
  pb-[2px]
`)


const Label = styled.div(`
  font-medium
  relative
  block
  select-none
  text-xl
  mt-2
  mb-6
  before:block
  before:float-right
  before:h-9
  before:w-14
`)

const inputTagNames = new Set([
  'INPUT',
  'TEXTAREA',
  'SELECT',
])

import signal from '#/signal'

const [useRenderCount, store] = signal(0)

if (import.meta.hot) {
  import.meta.hot.accept(() => store.getState().increment())
}

export default function AssessmentQuestion(props: Props.Parent) {
  const {
    actions,
    value,
    ...forwardProps
  } = props

  const {
    autoFocus,
    isActive,
    question,
    question: {
      id,
      render: {
        shouldLockFocus,
      },
      type,
    },
  } = forwardProps

  const renderCount = useRenderCount()
  const form = useRef<'form'>()

  const submit = useCallback(() => {
    if (!form.current?.reportValidity()) {
      return
    }

    const inputs = [...form.current.elements]
      .filter(isInput)
      .filter(input => input.name)


    if (inputs.length) {
      actions.select({
        id,
        value: inputs.length > 1 ?
          inputs.toObject(i => [i.name, i.value]) :
          inputs[0].value,
      })
      
    } else {
      actions.next()
    }
  }, [actions, id, form])


  const handleSubmit = useCallback((event: Dx.Event.Form) => {
    event.preventDefault()
    submit()
  }, [submit])

  const QuestionType = types[type] as (props: Props.Any) => React.ReactNode

  return (
    <Form
      data-question-id={question.id}
      data-question-name={question.name}
      onSubmit={handleSubmit}
      ref={form}>
      <FocusLock
        id={question.id}
        shouldLock={shouldLockFocus}
        isActive={isActive}
        autoFocus={autoFocus}>

        {'text' in question && question.text && question.render.text &&
          <Label>
            {question.text}
          </Label>}

        <QuestionType
          {...forwardProps}
          value={value}
          autoFocus={autoFocus}
          key={renderCount}
          submit={submit} />
      </FocusLock>
    </Form>
  )
}

function isInput(element: Element): element is HTMLInputElement {
  return inputTagNames.has(element.tagName)
}
